@import "./variables";

.quiz {
  background-color: $mainBg;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 130px);
  width: 100%;
  padding-bottom: 130px;
  position: relative;
  color: white;

  .title {
    font-size: 60px;
    margin-bottom: 20px;
  }

  .slogan {
    font-size: 40px;
    font-weight: 300;
  }

  .learnmore {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $orange;
    color: $white;
    border: none;
    padding: 15px 50px;
    font-size: 18px;
    cursor: pointer;
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    ion-icon {
      font-size: 20px;
      margin-left: 10px;
    }
  }

  .questionBox {
    // border: 2px solid red;
    width: fit-content;
    height: fit-content;

    .question {
      font-size: 38px;
      font-weight: 300;
    }
    .suggestion {
      font-size: 25px;
      font-weight: 300;
      margin-top: 5px;
    }
    .checks {
      //   border: 2px solid red;
      margin-top: 20px;
      flex-direction: column;

      > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        input {
          display: none;
        }
        input[type="radio"]:checked + .box::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 15px;
          width: 15px;
          border-radius: 3px;
          background-color: $orange;
        }

        .box {
          height: 22px;
          width: 22px;
          background-color: $mainBg;
          border: 2px solid $orange;
          margin-right: 10px;
          border-radius: 5px;
          position: relative;
        }

        label {
          cursor: pointer;
        }

        .text {
          font-size: 28px;
          cursor: pointer;
        }
      }
    }

    .dropDown {
      width: 400px;
      position: relative;
      user-select: none;

      .dropDown-Btn {
        padding: 15px 20px;
        background: $drop;
        border-radius: 5px;
        box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
        font-weight: 500;
        font-size: 18px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
      .dropDown-content {
        position: absolute;
        top: 120%;
        left: 0;
        padding: 10px;
        background: $drop;
        box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
        font-weight: 300;
        border-radius: 5px;
        color: white;
        z-index: 2;
        width: 100%;

        .dropDown-item {
          padding: 10px;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            background-color: $mainBg;
          }
        }
      }
    }

    .btn {
      background-color: $orange;
      padding: 12px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      outline: none;
      border: none;
      margin-top: 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      z-index: 1;

      ion-icon {
        margin-left: 5px;
      }
    }
  }

  .preNextBtns {
    // border: 2px solid red;
    position: absolute;
    bottom: 100px;
    right: 300px;
    display: flex;
    gap: 5px;

    button {
      background-color: $orange;
      color: white;
      border: none;
      outline: none;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      ion-icon {
        font-size: 20px;
        font-weight: 100;
      }

      &:disabled {
        color: rgba(255, 152, 96, 0.9);
        cursor: not-allowed;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .quiz {
    background-color: $mainBg;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    padding: 0 10px;
    padding-bottom: 0;
    position: relative;
    color: white;
    // border: 2px solid red;

    .title {
      font-size: 30px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 60px;
    }

    .slogan {
      font-size: 20px;
      font-weight: 300;
      text-align: center;
    }

    .learnmore {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $orange;
      color: $white;
      border: none;
      padding: 13px 40px;
      font-size: 18px;
      cursor: pointer;
      clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

      ion-icon {
        font-size: 20px;
        margin-left: 10px;
      }
    }

    .questionBox {
      // border: 2px solid red;
      width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      &.activeQueBox {
        height: calc(100vh - 190px);
      }

      .question {
        font-size: 25px;
        font-weight: 300;
        text-align: center;
        width: 80%;
      }
      .suggestion {
        font-size: 20px;
        font-weight: 300;
        margin-top: 5px;
        text-align: center;
      }
      .checks {
        //   border: 2px solid red;
        margin-top: 10px;
        align-self: center;
        flex-direction: column;

        > div {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          input {
            display: none;
          }
          input[type="radio"]:checked + .box::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 12px;
            width: 12px;
            border-radius: 3px;
            background-color: $orange;
          }

          .box {
            height: 20px;
            width: 20px;
            background-color: $mainBg;
            border: 2px solid $orange;
            margin-right: 10px;
            border-radius: 3px;
            position: relative;
          }

          label {
            cursor: pointer;
          }

          .text {
            font-size: 24px;
            cursor: pointer;
          }
        }
      }

      .dropDown {
        width: 80vw;
        position: relative;
        user-select: none;

        .dropDown-Btn {
          padding: 15px 20px;
          background: $drop;
          border-radius: 5px;
          box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
          font-weight: 500;
          font-size: 18px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        }
        .dropDown-content {
          position: absolute;
          top: 120%;
          left: 0;
          padding: 10px;
          background: $drop;
          box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
          font-weight: 300;
          border-radius: 5px;
          color: white;
          width: 100%;

          .dropDown-item {
            padding: 10px;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
              background-color: $mainBg;
            }
          }
        }
      }

      .btn {
        background-color: $orange;
        align-self: center;
        padding: 12px 30px;
        color: white;
        outline: none;
        border: none;
        margin-top: 20px;
        border-radius: 0;
        cursor: pointer;
        font-size: 16px;
        clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
      }
    }

    .preNextBtns {
      display: none;
    }
  }
}
