@import "./variables";

.referralPage {
  position: relative;

  .referralDetails {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $mainBg;
    align-items: center;
    padding: 50px 100px;
    color: white;

    .title {
      font-size: 40px;
    }

    .desc {
      font-size: 20px;
      width: 60%;
      text-align: center;
      margin-top: 20px;

      span {
        color: $orange;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .details {
      display: flex;
      gap: 20px;
      margin-top: 20px;

      .detailBox {
        height: 300px;
        width: 300px;
        //   border: 2px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;

        img {
          height: 120px;
          width: 120px;
        }

        .detailDesc {
          font-size: 18px;
          width: 70%;
          text-align: center;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 60%;
      margin-top: 30px;

      label {
        font-size: 20px;
        margin-bottom: 20px;
      }

      .formField {
        border: 2px solid white;
        border-radius: 5px;
        display: flex;
        width: 100%;
        overflow: hidden;

        input {
          width: 90%;
          border: none;
          background-color: $mainBg;
          padding: 20px 10px;
          font-size: 20px;
          color: white;
          outline: none;

          &::placeholder {
            color: gray;
          }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 100px $mainBg inset !important;
        }

        input:-webkit-autofill {
          -webkit-text-fill-color: white !important;
        }
        button {
          width: 10%;
          border: none;
          font-size: 20px;
          background-color: $orange;
          color: white;
          cursor: pointer;
          outline: none;
        }
      }
    }
  }

  .referralStatus {
    background-color: black;
    width: 100%;
    height: fit-content;
    padding: 50px 100px;
    display: flex;
    color: white;

    .left {
      width: 50%;
      // border: 2px solid red;
      display: flex;
      flex-direction: column;
      justify-content: center;

      form {
        display: flex;
        flex-direction: column;
        width: 60%;

        label {
          font-size: 20px;
          margin-bottom: 20px;
        }

        .formField {
          border: 1px solid white;
          border-radius: 5px;
          display: flex;
          width: 100%;
          overflow: hidden;

          input {
            width: 80%;
            border: none;
            background-color: $input;
            padding: 16px 10px;
            font-size: 18px;
            color: white;
            outline: none;

            &::placeholder {
              color: gray;
            }
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 100px $input inset !important;
          }

          input:-webkit-autofill {
            -webkit-text-fill-color: white !important;
          }
          button {
            width: 20%;
            border: none;
            background-color: $orange;
            color: white;
            cursor: pointer;
            font-size: 16px;
            outline: none;
          }
        }
      }

      .shortLinks {
        // border: 2px solid red;
        margin-top: 20px;
        display: flex;
        gap: 20px;
        align-items: center;

        a {
          ion-icon {
            color: $orange;
            font-size: 30px;
          }
          &.disabled {
            opacity: 0.7;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
    }
    .right {
      width: 50%;
      // border: 2px solid red;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 50px;

      .item {
        //   border: 2px solid red;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        .itemValue {
          font-size: 60px;
          font-weight: 700;
        }
        .itemTitle {
          text-align: center;
          font-size: 16px;
        }
      }
    }
  }

  .popUpLayer {
    // border: 2px solid red;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .popUpBox {
      // border: 2px solid red;
      background-color: white;
      padding: 20px 30px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 80vw;
      height: 80vh;

      ion-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 25px;
        cursor: pointer;
      }

      .title {
        font-size: 30px;
        margin-bottom: 20px;
      }

      .conditions {
        overflow: auto;
        padding: 10px;
        height: 90%;

        h2 {
          font-size: 22px;
        }

        .subdetail {
          margin-left: 50px;
        }

        p,
        li {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .referralPage {
    .referralDetails {
      height: fit-content;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $mainBg;
      align-items: center;
      color: white;
      // border: 2px solid red;
      padding: 10px;

      .title {
        font-size: 25px;
        text-align: center;
        font-weight: 500;
        // margin: 20px 0;
        // border: 2px solid red;
        // margin: 0;
      }

      .desc {
        font-size: 20px;
        width: 100%;
        text-align: center;
        margin-top: 20px;

        span {
          color: $orange;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin: 20px 0;

        .detailBox {
          height: auto;
          width: 100%;
          // border: 2px solid red;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          img {
            height: 120px;
            width: 120px;
          }

          .detailDesc {
            font-size: 18px;
            width: 80%;
            text-align: center;
          }
        }
      }

      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
        // border: 2px solid red;
        margin-bottom: 30px;

        label {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 20px;
          text-align: center;
        }

        .formField {
          border: 2px solid white;
          border-radius: 5px;
          display: flex;
          width: 100%;
          overflow: hidden;

          input {
            width: 80%;
            border: none;
            background-color: $input;
            padding: 20px 10px;
            font-size: 20px;
            color: white;
            outline: none;

            &::placeholder {
              color: gray;
            }
          }
          button {
            width: 20%;
            border: none;
            font-size: 20px;
            background-color: $orange;
            color: white;
            cursor: pointer;
            outline: none;
          }
        }
      }
    }

    .referralStatus {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 85%,
        rgba(34, 34, 34, 1) 100%
      );
      width: 100%;
      height: fit-content;
      padding: 30px 10px;
      padding-top: 60px;
      display: flex;
      flex-direction: column;
      color: white;

      .left {
        width: 100%;
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;

        form {
          display: flex;
          flex-direction: column;
          width: 100%;

          label {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 20px;
          }

          .formField {
            border: 1px solid white;
            border-radius: 5px;
            display: flex;
            width: 100%;
            overflow: hidden;

            input {
              width: 80%;
              border: none;
              background-color: $input;
              padding: 20px 10px;
              font-size: 16px;
              color: white;
              outline: none;

              &::placeholder {
                color: gray;
              }
            }
            button {
              width: 20%;
              border: none;
              background-color: $orange;
              color: white;
              cursor: pointer;
              font-size: 16px;
              outline: none;
            }
          }
        }

        .shortLinks {
          // border: 2px solid red;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          gap: 20px;
          align-items: center;

          ion-icon {
            color: $orange;
            font-size: 30px;
          }
        }
      }
      .right {
        width: 100%;
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .item {
          // border: 2px solid red;
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          .itemValue {
            font-size: 60px;
            font-weight: 700;
          }
          .itemTitle {
            text-align: center;
            font-size: 20px;
          }
        }
      }
    }

    .popUpLayer {
      // border: 2px solid red;
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 3;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      .popUpBox {
        // border: 2px solid red;
        background-color: white;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 80vw;
        height: 80vh;
        // border: 2px solid red;

        ion-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 25px;
          cursor: pointer;
        }

        .title {
          font-size: 25px;
          margin-bottom: 20px;
          // border: 2px solid red;
          // margin-left: 0;
          margin-top: 20px;
        }
        .conditions {
          overflow: auto;
          padding: 5px;
          height: 90%;

          .subdetail {
            margin-left: 30px;
          }
          // border: 2px solid red;
          h2 {
            font-size: 20px;
          }
          p {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
