@import "./variables";

.footer {
  background-color: $mainBg;
  color: white;
  display: flex;
  align-items: center;
  padding: 50px 150px;
  height: 300px;
  // border: 2px solid red;

  .left,
  .center,
  .right {
    // width: calc(100% / 2);
    // border: 2px solid red;
    height: 100%;
  }

  .left {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    // .logo {
    img {
      height: 150px;
      margin-bottom: 10px;
      // width: auto;
    }
    p {
      font-size: 20px;
      font-weight: 500;

      span {
        font-weight: 300;
      }
    }
    // }
  }
  .center {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 100px;
    width: 55%;
    // border: 2px solid red;

    // h2 {
    //   font-size: 30px;
    //   margin-bottom: 20px;
    // }

    ul {
      list-style: none;
      display: flex;
      gap: 30px;
      // margin-bottom: 20px;

      li {
        text-align: center;
        margin: 10px 0;
        a {
          text-decoration: none;
          color: white;
          font-weight: 300;
          font-size: 18px;
        }
      }
    }

    .socialIcons {
      // border: 2px solid red;
      display: flex;
      gap: 30px;

      a {
        border: 2px solid white;
        font-size: 25px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 50%;
      }
    }

    .branding {
      font-size: 18px;
      color: white;
      text-decoration: none;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-top: 10px;

    .detail {
      //   border: 2px solid red;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 500;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    background-color: $mainBg;
    color: white;
    display: flex;
    flex-direction: column;
    // padding: 20px;
    padding: 40px 20px;
    align-items: center;
    height: fit-content;
    // border: 2px solid red;

    .left,
    .center,
    .right {
      width: 100%;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // .logo {
      img {
        height: 130px;
        margin-bottom: 10px;
        // width: auto;
      }
      p {
        font-size: 20px;
        font-weight: 500;
        text-align: center;

        span {
          font-weight: 300;
        }
      }
      // }
    }
    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 0;
      width: 100%;
      // border: 2px solid red;

      // h2 {
      //   font-size: 30px;
      //   margin-bottom: 20px;
      // }

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0;
        margin-top: 10px;
        // margin-bottom: 20px;

        li {
          text-align: center;
          margin: 10px 0;
          a {
            text-decoration: none;
            color: white;
            font-weight: 300;
            font-size: 20px;
          }
        }
      }

      .socialIcons {
        // border: 2px solid red;
        display: flex;
        gap: 30px;
        margin: 10px 0;

        a {
          border: 2px solid white;
          font-size: 25px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          border-radius: 50%;
        }
      }

      .branding {
        font-size: 20px;
        margin-top: 10px;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      padding-top: 10px;
      // border: 2px solid red;

      .detail {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}
