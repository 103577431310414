@import "./variables";

.ourServices {
  background-color: $mainBg;
  color: white;
  height: 100vh;
  width: 100%;
  position: relative;

  .serviceDetailBox {
    // border: 2px solid red;
    width: 30%;
    position: absolute;
    top: 50px;
    left: 100px;
    z-index: 5;

    h2 {
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .desc {
      font-size: 18px;
      text-align: justify;
    }
  }

  .serviceContainer {
    // border: 2px solid red;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    padding: 0;

    .slider {
      // border: 2px solid red;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      gap: 150px;

      .preAllSlides {
        height: 400px;
        width: 300px;
        position: relative;
        margin-bottom: -200px;

        .slide {
          position: absolute;
          top: 0;
          left: 0;
          filter: blur(1px);
          transition: opacity 0.5s linear;
          opacity: 0;

          .slideDesc {
            display: none;
          }

          &::after {
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
          }

          &.preSlide {
            opacity: 1;
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          .slideDesc {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 10px;

            p {
              text-align: center;
            }
          }

          &.activeSlide {
            filter: none;
            &::after {
              background-color: transparent;
            }
            .slideDesc {
              display: block;
            }
          }
        }
      }

      .activeAllSlides {
        height: 400px;
        width: 300px;
        position: relative;

        .slide {
          position: absolute;
          opacity: 0;
          top: 0;
          transition: opacity 0.5s linear;
          left: 0;

          .slideDesc {
            display: block;
          }

          &.activeSlide {
            opacity: 1;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          .slideDesc {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 10px;

            p {
              text-align: center;
              margin: 0;
              // line-height: normal;
            }
          }
        }
      }

      .nextAllSlides {
        height: 400px;
        width: 300px;
        position: relative;
        margin-top: -200px;

        .slide {
          position: absolute;
          top: 0;
          left: 0;
          filter: blur(1px);
          margin-bottom: -250px;
          opacity: 0;
          transition: opacity 0.5s linear;

          .slideDesc {
            display: none;
          }

          &::after {
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
          }

          &.nextSlide {
            opacity: 1;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          .slideDesc {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 10px;

            p {
              text-align: center;
            }
          }

          &.activeSlide {
            filter: none;
            &::after {
              background-color: transparent;
            }
            .slideDesc {
              display: block;
            }
          }
        }
      }
    }

    .slideController {
      // border: 2px solid red;
      position: absolute;
      bottom: 100px;
      right: 100px;
      margin: 0;
      width: 300px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .controller {
        // border: 2px solid red;
        cursor: pointer;

        p {
          margin: 0;
          font-size: 25px;
          font-weight: 500;
          color: rgb(144, 144, 144);
          // border: 2px solid red;
          padding: 10px 0;
          transition: all 0.5s ease;

          &.active {
            border-bottom: 4px dashed $orange;
            width: fit-content;
            color: white;
          }
        }

        &:hover p {
          color: white;
        }
      }

      .learnmore {
        align-self: flex-start;
        margin-left: -15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $orange;
        color: $white;
        border: none;
        padding: 15px 40px;
        font-size: 16px;
        cursor: pointer;
        clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        border-radius: 0;

        ion-icon {
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }
  }
  .backgroundFontsMobile {
    display: none;
  }

  .backgroundFonts {
    position: absolute;
    top: 50px;
    right: 250px;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .ourServices {
    background-color: $mainBg;
    color: white;
    height: fit-content;
    width: 100%;
    // border: 2px solid red;
    position: relative;
    padding: 0;

    .serviceDetailBox {
      // border: 2px solid red;
      width: 100%;
      padding: 10px;
      position: static;
      top: 0;
      left: 0;
      z-index: 1;

      h2 {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 10px;
        text-transform: uppercase;
        text-align: center;
        position: relative;

        &::before {
          content: "";
          width: 48px;
          height: 5px;
          top: -12px;
          left: 50%;
          transform: skew(-40deg) translateX(-50%);
          position: absolute;
          background-color: $orange;
        }
      }

      .desc {
        font-size: 18px;
        text-align: center;
      }
    }

    .serviceContainer {
      // border: 2px solid red;
      width: 100%;
      height: fit-content;
      overflow: hidden !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;
      margin-top: 20px;

      .slider {
        all: initial;
        * {
          all: unset;
        }
      }

      .slider {
        height: fit-content;
        // border: 2px solid red;
        display: flex;
        gap: 20px;
        overflow: hidden;
        // height: fit-content;

        .preAllSlides,
        .activeAllSlides,
        .nextAllSlides {
          width: 60vw;
          height: 85vw;

          .slide {
            transition: opacity 0.5s linear;
          }
        }

        .preAllSlides {
          // border: 2px solid red;
          position: relative;
          margin: 0;
          margin-top: 100px;

          .slide {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            filter: blur(1px);

            .slideDesc {
              display: none;
            }

            &.preSlide {
              opacity: 1;
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
        .activeAllSlides {
          // border: 2px solid red;
          position: relative;
          margin-top: 50px;

          .slide {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            &.activeSlide {
              opacity: 1;
            }

            .slideDesc {
              position: absolute;
              bottom: 0;
              left: 0;
              padding: 5px;
              background-color: rgba(0, 0, 0, 0.5);
              // border: 2px solid red;
              display: flex;
              justify-content: center;
              align-items: center;

              p {
                text-align: center;
                color: white;
                width: 100%;
                font-family: "Maven Pro", sans-serif !important;
              }
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
        .nextAllSlides {
          // border: 2px solid red;
          position: relative;
          margin-bottom: 0;
          margin-top: 0;

          .slide {
            position: absolute;
            height: 100%;
            width: 100%;
            filter: blur(1px);
            top: 0;
            left: 0;
            opacity: 0;

            .slideDesc {
              display: none;
            }

            &.nextSlide {
              opacity: 1;
            }

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .slideController {
        // border: 2px solid red;
        position: static;
        bottom: 0;
        right: 0;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        .controller {
          // border: 2px solid red;
          cursor: pointer;

          p {
            font-size: 25px;
            font-weight: 500;
            color: rgb(144, 144, 144);
            // border: 2px solid red;
            padding: 5px 0;
            transition: all 0.5s ease;
            margin: 0;

            &.active {
              border-bottom: 3px dashed $orange;
              width: fit-content;
              color: white;
            }
          }

          &:hover p {
            color: white;
          }
        }

        .learnmore {
          align-self: center;
          margin-left: 0;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $orange;
          color: $white;
          border: none;
          padding: 15px 35px;
          font-size: 16px;
          cursor: pointer;
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

          ion-icon {
            font-size: 20px;
            margin-left: 10px;
          }
        }
      }
    }

    .backgroundFonts {
      display: none;
    }
    .backgroundFontsMobile {
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 2px solid red;
      margin: 5px 0;

      img {
        width: 100%;
      }
    }
  }
}
