@import "./variables";

.whoAreWe {
  width: 100%;
  height: fit-content;
  padding: 100px 0;
  background-color: $mainBg;
  color: white;
  display: flex;
  position: relative;
  // border: 2px solid red;

  .left {
    width: 55%;
    // border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .imgbox {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-right: 70px;
      // border: 2px solid red;

      img {
        height: 550px;
        object-fit: cover;
      }
    }
  }
  .right {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 50px;
      margin-right: 100px;
      margin-left: 50px;
      font-weight: 500;
      text-transform: uppercase;
      position: relative;

      &::before {
        content: "";
        width: 48px;
        height: 5px;
        top: -12px;
        left: 0;
        transform: skew(-40deg);
        position: absolute;
        background-color: $orange;
      }
    }
    .desc {
      margin-left: 50px;
      margin-right: 100px;
      text-align: justify;
      line-height: 22px;
      font-size: 18px;
      p {
        margin-top: 20px;
      }
    }
    .learnmore {
      align-self: flex-start;
      margin-left: 50px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $orange;
      color: $white;
      border: none;
      padding: 13px 40px;
      font-size: 16px;
      cursor: pointer;
      clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

      ion-icon {
        font-size: 20px;
        margin-left: 10px;
      }
    }
  }

  .whoareweBgFontMobile {
    display: none;
  }

  .whoareweBgFont {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 80px;
    img {
      height: 550px;
      //   border: 2px solid red;
    }
  }
}

@media screen and (max-width: 768px) {
  .whoAreWe {
    width: 100%;
    height: fit-content;
    padding: 0;
    background-color: $mainBg;
    color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    // border: 2px solid red;

    .left {
      width: 100%;
      // border: 2px solid red;
      // margin-top: 100px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      justify-content: flex-end;

      .imgbox {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-right: 0;
        // border: 2px solid red;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // border: 2px solid red;
      padding: 60px 10px;

      .title {
        font-size: 30px;
        margin-right: 0;
        margin-left: 0;
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        text-align: center;
        // border: 2px solid red;

        &::before {
          content: "";
          width: 48px;
          height: 5px;
          top: -12px;
          left: 50%;
          transform: skew(-40deg) translateX(-50%);
          position: absolute;
          background-color: $orange;
        }
      }
      .desc {
        margin-left: 0;
        margin-right: 0;
        text-align: center;
        line-height: 22px;
        font-size: 16px;
        padding: 0 10px;
        p {
          margin-top: 20px;
        }
      }
      .learnmore {
        align-self: center;
        margin-left: 0;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $orange;
        color: $white;
        border: none;
        padding: 13px 40px;
        font-size: 16px;
        cursor: pointer;
        clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

        ion-icon {
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }

    .whoareweBgFont {
      display: none;
    }
    .whoareweBgFontMobile {
      // border: 2px solid red;
      display: block;
      margin: 10px 0;
      margin-right: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        // border: 2px solid red;
        height: 40px;
      }
    }
  }
}
