@import "./variables";

.adminMainContent {
  .right {
    background-color: $mainBg;
    width: 100%;
    height: 100%;
    padding-left: 250px;
    padding-top: 50px;
    color: white;
  }

  button {
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px $mainBg inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .adminSales {
    h2 {
      font-weight: 500;
    }

    form {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 800px;
      margin-left: 20px;

      .fieldTitle {
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 8px;
      }

      .formField {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .dateBox {
          display: flex;
          width: 70%;
          //   border: 2px solid red;
          justify-content: space-between;

          .subFormField {
            // border: 2px solid red;
            display: flex;
            flex-direction: column;
            width: 45%;

            input[type="text"],
            input[type="number"] {
              width: 100%;

              &#discount {
                width: 70%;
              }
            }

            label {
              font-size: 14px;
              margin-bottom: 5px;
            }
          }
        }

        textarea {
          width: 100%;
          height: 100px;
          resize: none;
          background: none;
          border: 1px solid white;
          border-radius: 5px;
          outline: none;
          padding: 10px;
          color: white;
          font-size: 16px;
          font-weight: 300;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        input[type="text"],
        input[type="number"] {
          background: none;
          outline: none;
          padding: 13px 10px;
          border: 1px solid white;
          width: 40%;
          border-radius: 5px;
          color: white;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        input[type="date"] {
          background: none;
          outline: none;
          padding: 10px;
          cursor: pointer;
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }

        label {
          font-size: 16px;
          margin-bottom: 8px;
        }

        .fieldTitle {
          font-weight: 300;
          font-size: 18px;
          margin-bottom: 8px;
        }
      }

      button {
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        width: fit-content;
        outline: none;
        margin-top: 10px;
      }
    }
  }

  .adminHistory {
    padding-right: 20px;
    padding-bottom: 50px;
    h2 {
      font-weight: 500;
    }
    .filterContainer {
      // border: 2px solid red;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .filter {
        display: flex;
        align-items: center;
        gap: 10px;

        .filterText {
          font-size: 18px;
          margin-right: 5px;
        }
        .dropDown {
          width: max-content;
          min-width: 200px;
          position: relative;
          user-select: none;
          // margin: 0 10px;

          .dropDown-Btn {
            padding: 8px 13px;
            background: gray;
            border-radius: 5px;
            box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
            font-weight: 300;
            font-size: 16px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            ion-icon {
              margin-left: 5px;
            }
          }

          .dropDown-content {
            position: absolute;
            top: 120%;
            left: 0;
            padding: 3px 0;
            background: gray;
            box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
            font-weight: 300;
            border-radius: 5px;
            color: white;
            z-index: 2;
            width: 100%;
            z-index: 4;

            .dropDown-item {
              padding: 8px;
              cursor: pointer;
              transition: all 0.2s;

              &:hover {
                background-color: $drop;
              }
            }
          }
        }
      }

      .exportBtn {
        // border: 2px solid red;
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        width: fit-content;
      }
    }

    .tableDiv {
      margin-top: 20px;
      // margin-bottom: 100px;
      // border: 2px solid red;
      height: 85%;
      position: relative;
      overflow: auto;
      table {
        border-collapse: collapse;
        border-spacing: 0 10px;
        width: max-content;
        min-width: 100%;
        border: none;
        overflow-x: auto;
      }

      .noData {
        // border: 2px solid red;
        text-align: center;
        width: 100%;
        font-size: 20px;
        padding: 20px 0;
      }

      th,
      td {
        text-align: left;
        padding: 8px 8px;
        text-align: center;
        // width: 300px;
      }
      th {
        background: $drop;
        font-weight: 500;
        font-size: 18px !important;
        padding: 10px;
        position: sticky;
        top: 0;
        z-index: 3;
      }

      .bannerText {
        // border: 2px solid red;
        text-align: left;
        max-width: 300px;
        word-wrap: break-word;
      }

      .status {
        // border: 2px solid red;
        border: 2px solid white;
        &.running {
          background-color: #3ccf4e;
        }
        &.completed {
          background-color: #e64848;
        }
      }

      .edit,
      .end {
        // border: 2px solid red;
        // display: flex;
        // width: fit-content;
        button {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          background: none;
          margin: auto;
          border: none;
          cursor: pointer;
          img {
            height: 100%;
            width: 100%;
            -webkit-user-drag: none;
          }
          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .adminSettings {
    h2 {
      font-weight: 500;
    }

    form {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      width: 80%;
      margin-top: 30px;

      .fields {
        // border: 2px solid red;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .formField {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          //   border: 2px solid red;
          width: 45%;

          input {
            background: none;
            outline: none;
            padding: 10px;
            border: 1px solid white;
            border-radius: 5px;
            color: white;
            width: 100%;
            //   font-size: 16px;

            &::placeholder {
              color: white;
              opacity: 0.7;
              font-weight: 0;
            }
          }

          .fieldTitle {
            font-weight: 300;
            font-size: 18px;
            margin-bottom: 8px;
          }
        }
      }

      button {
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        width: fit-content;
        margin-top: 10px;
      }
    }

    .form2 {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      width: 80%;
      margin-top: 50px;
      .fieldTitle {
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 8px;
      }

      .fields {
        // border: 2px solid red;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .formField {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          //   border: 2px solid red;
          width: 30%;

          input {
            background: none;
            outline: none;
            padding: 10px;
            border: 1px solid white;
            border-radius: 5px;
            color: white;
            width: 100%;
            //   font-size: 16px;

            &::placeholder {
              color: white;
              opacity: 0.7;
              font-weight: 0;
            }
          }
        }
      }

      button {
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        width: fit-content;
      }
    }
  }
  .adminOurDetails {
    h2 {
      font-weight: 500;
    }

    form {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      width: 80%;
      margin-top: 30px;

      .fields {
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .formField {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          //   border: 2px solid red;
          width: 45%;

          input {
            background: none;
            outline: none;
            padding: 10px;
            border: 1px solid white;
            border-radius: 5px;
            color: white;
            width: 100%;
            //   font-size: 16px;

            &::placeholder {
              color: white;
              opacity: 0.7;
              font-weight: 0;
            }
          }

          .fieldTitle {
            font-weight: 300;
            font-size: 18px;
            margin-bottom: 8px;
          }
        }
      }

      button {
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        width: fit-content;
        margin-top: 10px;
      }
    }
  }

  .adminBannerUpdate {
    h2 {
      font-weight: 500;
    }

    form {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 800px;
      margin-left: 20px;

      .fieldTitle {
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 8px;
      }

      .formField {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .dateBox {
          display: flex;
          width: 70%;
          //   border: 2px solid red;
          justify-content: space-between;

          .subFormField {
            // border: 2px solid red;
            display: flex;
            flex-direction: column;
            width: 45%;

            input[type="text"],
            input[type="number"] {
              width: 100%;

              &#discount {
                width: 70%;
              }
            }

            label {
              font-size: 14px;
              margin-bottom: 5px;
            }
          }
        }

        textarea {
          width: 100%;
          height: 100px;
          resize: none;
          background: none;
          border: 1px solid white;
          border-radius: 5px;
          outline: none;
          padding: 10px;
          color: white;
          font-size: 16px;
          font-weight: 300;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        input[type="text"],
        input[type="number"] {
          background: none;
          outline: none;
          padding: 13px 10px;
          border: 1px solid white;
          width: 40%;
          border-radius: 5px;
          color: white;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        input[type="date"] {
          background: none;
          outline: none;
          padding: 10px;
          cursor: pointer;
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }

        label {
          font-size: 16px;
          margin-bottom: 8px;
        }

        .fieldTitle {
          font-weight: 300;
          font-size: 18px;
          margin-bottom: 8px;
        }
      }

      button {
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        width: fit-content;
        margin-top: 10px;
      }
    }
  }

  .adminSingleCohort {
    padding-right: 20px;
    overflow-y: auto;
    // border: 2px solid red;
    padding-bottom: 50px;
    overflow-x: hidden;
    // position: relative;

    .topCohortBar {
      display: flex;
      justify-content: space-between;
      // border: 2px solid red;
      h2 {
        font-weight: 500;
      }

      .deleteCohort {
        border: none;
        background-color: #fb2c2c;
        padding: 10px;
        width: 100px;
        color: white;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 5px;
      }
    }

    form {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      width: 80%;
      margin-top: 30px;

      .fields {
        // border: 2px solid red;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .formField {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        //   border: 2px solid red;
        width: 100%;

        input {
          background: none;
          outline: none;
          padding: 10px;
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          width: 50%;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        textarea {
          width: 100%;
          height: 120px;
          resize: none;
          background: none;
          border: 1px solid white;
          border-radius: 5px;
          outline: none;
          padding: 10px;
          color: white;
          font-size: 16px;
          font-weight: 300;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        .fieldTitle {
          font-weight: 300;
          font-size: 18px;
          margin-bottom: 8px;
        }
        // }
      }

      button {
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        width: fit-content;
        margin-top: 10px;
      }
    }

    .tableDiv {
      margin-top: 20px;
      //   border: 2px solid red;
      position: relative;
      table {
        border-collapse: separate;
        border-spacing: 0 10px;
        width: 100%;
        border: none;
      }

      th,
      td {
        text-align: left;
        padding: 5px 8px;
        text-align: center;
      }
      th {
        background: $drop;
        font-weight: 500;
        font-size: 18px;
        padding: 10px;
        position: sticky;
        top: 0;
        z-index: 3;
      }

      .bannerText {
        // border: 2px solid red;
        text-align: left;
      }

      .status {
        // border: 2px solid red;
        color: black;
        font-weight: 500;
        &.Available {
          background-color: #3ccf4e;
        }
        &.Closed {
          background-color: #e64848;
        }
        &.Notify {
          background-color: #fff89a;
        }
      }

      .edit,
      .end {
        // border: 2px solid red;
        // width: 100px;
        // display: flex;
        // width: fit-content;
        button {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          margin: auto;
          // margin-left: 30px;
          background: none;
          border: none;
          cursor: pointer;
          img {
            height: 100%;
            width: 100%;
            -webkit-user-drag: none;
          }
        }
      }
    }

    .bottomCohortBar {
      display: flex;
      justify-content: center;
      // border: 2px solid red;
      margin-top: 20px;
      .addSlot {
        border: none;
        background-color: $orange;
        padding: 10px;
        width: 100px;
        color: white;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 5px;
      }
    }
  }

  .adminUpdateSlot {
    padding-right: 20px;
    overflow-y: auto;
    // border: 2px solid red;
    padding-bottom: 50px;
    overflow-x: hidden;
    h2 {
      font-weight: 500;
    }

    form {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-top: 30px;

      .addSlotBtn {
        // border: 2px solid red;
        padding: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        margin-right: 60px;
      }

      .fields {
        // border: 2px solid red;
        display: flex;
        // justify-content: flex-start;
        gap: 100px;
        width: 100%;
      }
      .formField {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        // border: 2px solid red;
        width: fit-content;

        &.deleteField {
          align-self: flex-end;
          padding-bottom: 3px;
        }

        .delSlot {
          // border: 2px solid red;
          padding: 10px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
        }

        input {
          background: none;
          outline: none;
          padding: 10px;
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          width: 50%;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        input[type="date"] {
          background: none;
          outline: none;
          padding: 10px;
          cursor: pointer;
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          width: 300px;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        .dropDown {
          width: 300px;
          position: relative;
          user-select: none;

          .dropDown-Btn {
            padding: 10px 15px;
            background: $drop;
            border-radius: 5px;
            box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
            font-weight: 500;
            font-size: 16px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
          }
          .dropDown-content {
            position: absolute;
            top: 120%;
            left: 0;
            padding: 10px;
            background: $drop;
            box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
            font-weight: 300;
            border-radius: 5px;
            color: white;
            z-index: 2;
            width: 100%;

            .dropDown-item {
              padding: 10px;
              cursor: pointer;
              transition: all 0.2s;

              &:hover {
                background-color: $mainBg;
              }
            }
          }
        }

        ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .fieldTitle {
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 8px;
      }

      .timeSlots {
        margin: 10px 0;
        .singleTimeSlot {
          display: flex;
          justify-content: flex-start;
          gap: 20px;
          align-items: center;
          // border: 2px solid red;
          .formField {
            width: fit-content;
            margin-bottom: 10px;
            .fieldTitle {
              font-size: 14px;
            }
            input {
              width: fit-content;
              margin: 0;
              padding: 8px;
            }
            .zoomLink {
              width: 450px;
            }
            .trainerName {
              width: 250px;
            }
          }
        }
      }

      button {
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        outline: none;
        width: fit-content;
        margin-top: 10px;
      }
    }
  }

  .adminCreateSlot {
    padding-right: 20px;
    overflow-y: auto;
    // border: 2px solid red;
    padding-bottom: 50px;
    overflow-x: hidden;
    h2 {
      font-weight: 500;
    }

    form {
      // border: 2px solid red;
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-top: 30px;

      .addSlotBtn {
        // border: 2px solid red;
        padding: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        margin-right: 60px;
      }

      .fields {
        // border: 2px solid red;
        display: flex;
        // justify-content: flex-start;
        gap: 100px;
        width: 100%;
      }
      .formField {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        // border: 2px solid red;
        width: fit-content;

        &.deleteField {
          align-self: flex-end;
          padding-bottom: 3px;
        }

        .delSlot {
          // border: 2px solid red;
          padding: 10px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
        }

        input {
          background: none;
          outline: none;
          padding: 10px;
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          width: 50%;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        input[type="date"] {
          background: none;
          outline: none;
          padding: 10px;
          cursor: pointer;
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          width: 300px;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        .dropDown {
          width: 300px;
          position: relative;
          user-select: none;

          .dropDown-Btn {
            padding: 10px 15px;
            background: $drop;
            border-radius: 5px;
            box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
            font-weight: 500;
            font-size: 16px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
          }
          .dropDown-content {
            position: absolute;
            top: 120%;
            left: 0;
            padding: 10px;
            background: $drop;
            box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
            font-weight: 300;
            border-radius: 5px;
            color: white;
            z-index: 2;
            width: 100%;

            .dropDown-item {
              padding: 10px;
              cursor: pointer;
              transition: all 0.2s;

              &:hover {
                background-color: $mainBg;
              }
            }
          }
        }

        ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .fieldTitle {
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 8px;
      }

      .timeSlots {
        margin: 10px 0;
        .singleTimeSlot {
          display: flex;
          justify-content: flex-start;
          gap: 20px;
          align-items: center;
          // border: 2px solid red;
          .formField {
            width: fit-content;
            margin-bottom: 10px;
            .fieldTitle {
              font-size: 14px;
            }
            input {
              width: fit-content;
              margin: 0;
              padding: 8px;
            }
            .zoomLink {
              width: 450px;
            }
            .trainerName {
              width: 250px;
            }
          }
        }
      }

      button {
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        width: fit-content;
        // margin: auto;
        margin-top: 10px;
      }
    }
  }

  .adminCreateCohort {
    padding-right: 20px;
    overflow-y: auto;
    // border: 2px solid red;
    padding-bottom: 50px;
    overflow-x: hidden;
    // position: relative;

    .topCohortBar {
      display: flex;
      justify-content: space-between;
      // border: 2px solid red;
      h2 {
        font-weight: 500;
      }

      .deleteCohort {
        border: none;
        background-color: #fb2c2c;
        padding: 10px;
        width: 100px;
        color: white;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 5px;
      }
    }

    form {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      width: 80%;
      margin-top: 30px;

      .fields {
        // border: 2px solid red;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .formField {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        //   border: 2px solid red;
        width: 100%;

        input {
          background: none;
          outline: none;
          padding: 10px;
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          width: 50%;
          //   font-size: 16px;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        textarea {
          width: 100%;
          height: 120px;
          resize: none;
          background: none;
          border: 1px solid white;
          border-radius: 5px;
          outline: none;
          padding: 10px;
          color: white;
          font-size: 16px;
          font-weight: 300;

          &::placeholder {
            color: white;
            opacity: 0.7;
            font-weight: 0;
          }
        }

        .fieldTitle {
          font-weight: 300;
          font-size: 18px;
          margin-bottom: 8px;
        }
        // }
      }

      button {
        background-color: $orange;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        width: fit-content;
        margin-top: 10px;
      }
    }

    .tableDiv {
      margin-top: 20px;
      //   border: 2px solid red;
      position: relative;
      table {
        border-collapse: separate;
        border-spacing: 0 10px;
        width: 100%;
        border: none;
      }

      th,
      td {
        text-align: left;
        padding: 5px 8px;
        text-align: center;
      }
      th {
        background: $drop;
        font-weight: 500;
        font-size: 18px;
        padding: 10px;
        position: sticky;
        top: 0;
        z-index: 3;
      }

      .bannerText {
        // border: 2px solid red;
        text-align: left;
      }

      .status {
        // border: 2px solid red;
        color: black;
        font-weight: 500;
        &.Available {
          background-color: #3ccf4e;
        }
        &.Closed {
          background-color: #e64848;
        }
        &.Notify {
          background-color: #fff89a;
        }
      }

      .edit,
      .end {
        // border: 2px solid red;
        // width: 100px;
        // display: flex;
        // width: fit-content;
        button {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          margin: auto;
          // margin-left: 30px;
          background: none;
          border: none;
          cursor: pointer;
          img {
            height: 100%;
            width: 100%;
            -webkit-user-drag: none;
          }
        }
      }
    }

    .bottomCohortBar {
      display: flex;
      justify-content: center;
      // border: 2px solid red;
      margin-top: 20px;
      .addSlot {
        border: none;
        background-color: $orange;
        padding: 10px;
        width: 100px;
        color: white;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 5px;
      }
    }
  }
}
