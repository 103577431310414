@import "./variables";

.allplans {
  width: 100%;
  background-color: $mainBg;
  height: fit-content;
  color: white;

  .planContainer {
    padding: 0 150px;
    padding-bottom: 50px;
    position: relative;

    .planDetails {
      display: flex;
      position: relative;
      margin-bottom: 50px;
      //   border: 2px solid red;

      .suggestion {
        position: absolute;
        top: 50px;
        color: $orange;
      }
      > .planLeft {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 50%;

        .serviceBgFontMobile {
          display: none;
        }
        // border: 2px solid red;

        > .title {
          font-size: 40px;
          font-weight: 500;
          margin-bottom: 120px;
        }

        > .desc {
          width: 90%;
          text-align: left;
          margin-bottom: 30px;
          font-size: 18px;
        }

        a {
          text-decoration: none;
          width: 90%;
          // border: 2px solid red;
          > button {
            background-color: $orange;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            margin-bottom: 40px;
            width: 100%;

            .btnTop {
              width: 100%;
              display: flex;
              align-items: center;
              // border: 2px solid red;
              justify-content: space-between;

              .btnTitle {
                font-size: 18px;
                font-weight: 700;
              }
              .price {
                font-size: 20px;
                font-weight: 300;
                letter-spacing: 1px;
                span {
                  font-size: 26px;
                  font-weight: 600;
                  margin-right: 5px;
                }
                .prev {
                  font-size: 20px;
                }
              }
            }
            .btnBottom {
              width: 100%;
              display: flex;
              justify-content: space-between;
              // border: 2px solid red;

              .btnDesc {
                font-size: 16px;
                font-weight: 300;
              }
              .subPrice {
                font-size: 16px;
                letter-spacing: 1px;
                font-weight: 0;

                .prev {
                  font-size: 14px;
                  margin-right: 10px;
                }
              }
            }
          }
        }

        .allSlotsContainer {
          // border: 2px solid red;
          width: 90%;
          display: flex;
          flex-direction: column;

          .slotSlogan {
            font-size: 30px;
            font-weight: 300px;
          }

          .currentSlots {
            // border: 2px solid red;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 10px;

            .currentSlot {
              display: flex;
              // border: 2px solid red;
              // justify-content: space-between;
              flex-direction: column;

              .daySlot {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .dates {
                  font-size: 20px;
                }
                .btn {
                  background-color: $orange;
                  color: white;
                  border-radius: 5px;
                  border: none;
                  padding: 8px 20px;
                  width: 110px;
                  cursor: pointer;
                  &:disabled {
                    opacity: 0.6;
                    cursor: not-allowed;
                  }
                }
              }

              .timeSlots {
                display: flex;
                gap: 10px;
                margin-top: 10px;
                justify-content: space-between;
                transition: all 0.5s ease;
                flex-wrap: wrap;

                .timeSlot {
                  display: flex;
                  width: 48%;
                  // border: 2px solid red;

                  input {
                    display: none;
                  }

                  input[type="radio"]:checked + .timeBox {
                    background-color: $orange;
                  }

                  // border: 2px solid red;
                  .timeBox {
                    border: 2px solid $orange;
                    border-radius: 5px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;
                    padding: 8px 5px;
                    color: white;
                    transition: all 0.5s ease;

                    &:hover {
                      background-color: $orange;
                    }

                    ion-icon {
                      color: white;
                      font-size: 25px;
                      transition: all 0.5s ease;
                    }
                  }
                }
              }
            }
          }
        }
      }

      > .planRight {
        width: 50%;
        // border: 2px solid red;
        display: flex;
        justify-content: flex-end;
        padding-top: 160px;
        img {
          height: 500px;
          // margin-right: 50px;
        }
      }
    }
    .serviceBgFont {
      position: absolute;
      top: 80px;
      left: 20px;
      img {
        // height: 50px;
        width: 100%;
      }
    }
  }

  .planContainer:nth-child(2n) {
    .planDetails {
      flex-direction: row-reverse;

      > .planLeft {
        align-items: flex-end;
      }

      > .planRight {
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .allplans {
    padding: 10px;
    width: 100%;
    background-color: $mainBg;
    height: fit-content;
    color: white;

    .planContainer {
      padding: 0;
      padding-bottom: 20px;
      position: relative;

      .planDetails {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        margin-bottom: 0;
        padding-top: 70px;

        &.newUserPlan {
          padding-top: 0;
        }
        // border: 2px solid red;

        .suggestion {
          position: absolute;
          top: 0;
          // border: 2px solid red;
          text-align: center;
          color: $orange;
        }
        > .planLeft {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          // border: 2px solid red;
          padding: 30px 0;
          padding-bottom: 0;

          .serviceBgFontMobile {
            display: flex;
            width: 100%;
            overflow: hidden;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 15px;

            img {
              height: 30px;
            }
          }

          > .title {
            font-size: 25px;
            text-align: center;
            font-weight: 500;
            margin-bottom: 15px;
            position: relative;

            &::before {
              content: "";
              width: 48px;
              height: 5px;
              top: -12px;
              left: 50%;
              transform: skew(-40deg) translateX(-50%);
              position: absolute;
              background-color: $orange;
            }
          }

          > .desc {
            width: 90%;
            text-align: center;
            margin-bottom: 20px;
            font-size: 18px;
          }

          a {
            text-decoration: none;
            width: 100%;
            // border: 2px solid red;
            > button {
              background-color: $orange;
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: white;
              padding: 10px 5px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              margin-bottom: 10px;
              width: 100%;

              .btnTop {
                width: 100%;
                display: flex;
                align-items: center;
                // border: 2px solid red;
                justify-content: space-between;

                .btnTitle {
                  font-size: 16px;
                  font-weight: 700;
                }
                .price {
                  font-size: 16px;
                  font-weight: 300;
                  letter-spacing: 1px;
                  span {
                    font-size: 22px;
                    font-weight: 600;
                    margin-right: 5px;
                  }
                }
              }
              .btnBottom {
                width: 100%;
                display: flex;
                justify-content: space-between;
                // border: 2px solid red;

                .btnDesc {
                  font-size: 14px;
                  font-weight: 300;
                }
                .subPrice {
                  font-size: 14px;
                  letter-spacing: 1px;
                  font-weight: 0;
                }
              }
            }
          }

          .allSlotsContainer {
            // border: 2px solid red;
            width: 100%;
            display: flex;
            flex-direction: column;

            .slotSlogan {
              font-size: 22px;
              font-weight: 300;
            }

            .currentSlots {
              // border: 2px solid red;
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-top: 20px;

              .currentSlot {
                display: flex;
                // border: 2px solid red;
                // justify-content: space-between;
                flex-direction: column;

                .daySlot {
                  display: flex;
                  justify-content: space-between;
                  .dates {
                    font-size: 16px;
                  }
                  .btn {
                    background-color: $orange;
                    color: white;
                    border-radius: 5px;
                    border: none;
                    padding: 8px 20px;
                    width: 110px;
                    cursor: pointer;
                    &:disabled {
                      opacity: 0.6;
                      cursor: not-allowed;
                    }
                  }
                }

                .timeSlots {
                  display: flex;
                  gap: 10px;
                  margin-top: 10px;
                  justify-content: space-between;
                  flex-wrap: wrap;

                  .timeSlot {
                    display: flex;
                    width: 48%;
                    // border: 2px solid red;

                    input {
                      display: none;
                    }

                    input[type="radio"]:checked + .timeBox {
                      background-color: $orange;
                    }

                    // border: 2px solid red;
                    .timeBox {
                      border: 2px solid $orange;
                      border-radius: 5px;
                      width: 100%;
                      display: flex;
                      align-items: center;
                      gap: 5px;
                      cursor: pointer;
                      padding: 8px 5px;
                      color: white;
                      transition: all 0.5s ease;

                      p {
                        font-size: 14px;
                      }

                      &:hover {
                        background-color: $orange;
                      }

                      ion-icon {
                        color: white;
                        font-size: 30px;
                        transition: all 0.5s ease;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        > .planRight {
          width: 100%;
          // border: 2px solid red;
          display: flex;
          justify-content: flex-end;
          padding-top: 0;
          img {
            height: auto;
            width: 100%;
            // margin-right: 50px;
          }
        }
      }
      .serviceBgFont {
        display: none;
      }
    }

    .planContainer:nth-child(2n) {
      .planDetails {
        flex-direction: column-reverse;

        > .planLeft {
          align-items: center;
        }

        > .planRight {
          justify-content: center;
        }
      }
    }
  }
}
