@import "./variables";

.overview {
  background-color: $mainBg;
  padding-top: 30px;
  overflow-x: hidden !important;
  width: 100%;
  padding-bottom: 10px;
  // border: 2px solid red;
  .slideImg {
    img {
      width: 100%;
      height: auto;
    }
  }

  .insta {
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-top: 20px;
    // text-align: center;
    // border: 2px solid red;

    .instaLink {
      // border: 2px solid red;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: $orange;
      font-weight: 500;
      text-decoration: none;
      // text-align: center;

      ion-icon {
        margin-right: 10px;
        font-size: 30px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .overview {
    background-color: $mainBg;
    padding-top: 10px;
    overflow-x: hidden !important;
    width: 100%;
    padding-bottom: 10px;
    // border: 2px solid red;
    .slideImg {
      margin-left: 0;

      img {
        width: 100%;
      }
    }
    .insta {
      display: flex;
      justify-content: center;
      padding: 10px 0;

      .instaLink {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: $orange;
        font-weight: 500;
        justify-content: center;
        // border: 2px solid red;

        i {
          margin-right: 10px;
          font-size: 20px;
        }
      }
    }
  }
}
