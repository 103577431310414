@import "./variables";

.chapters:hover > .chapter:not(:hover) {
  width: 0;
}

.chapters {
  height: calc(100vh - 130px);
  max-height: 600px;
  width: 100%;
  display: flex;
  overflow: hidden;

  .chapter {
    width: 25%;
    height: 100%;
    // transition: all 0.2s ease;
    overflow: hidden;
    display: flex;

    .left {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // transition: all 0.2s ease;

      .details {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h2 {
          font-size: 45px;
          color: white;
          text-align: center;
        }
        h3 {
          font-size: 35px;
          color: white;
          text-align: center;
        }
      }
    }

    .right {
      display: flex;
      justify-content: center;
      align-content: center;
      width: 75%;
      display: none;
      // transition: all 0.2s ease;

      .imgBox {
        width: 45%;
        height: 100%;
        // border: 2px solid red;
        // transition: all 0.2s ease;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          // object-position: 0 0;
        }
      }

      .storyBox {
        background-color: black;
        color: white;
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 40px;
        padding-right: 70px;
        // transition: all 0.2s ease;

        .title {
          font-size: 45px;
          text-transform: uppercase;
          position: relative;

          &::before {
            content: "";
            width: 48px;
            height: 5px;
            top: -12px;
            left: 0;
            transform: skew(-40deg);
            position: absolute;
            background-color: $orange;
          }
        }

        .desc {
          text-align: left;
          font-size: 18px;
          margin-top: 20px;
        }
      }
    }

    &.chap1 .left {
      background-image: linear-gradient(
          rgba(255, 90, 1, 0.6),
          rgba(255, 90, 1, 0.6)
        ),
        url("../Assets/chap1.png");
      background-size: cover;
      background-position: center;
    }
    &.chap2 .left {
      background-image: linear-gradient(
          rgba(255, 90, 1, 0.6),
          rgba(255, 90, 1, 0.6)
        ),
        url("../Assets/chap2.png");
      background-size: cover;
      background-position: center;
    }

    &.chap3 .left {
      background-image: linear-gradient(
          rgba(255, 90, 1, 0.6),
          rgba(255, 90, 1, 0.6)
        ),
        url("../Assets/chap3.png");
      background-size: cover;
      background-position: center;
    }
    &.chap4 .left {
      background-image: linear-gradient(
          rgba(255, 90, 1, 0.6),
          rgba(255, 90, 1, 0.6)
        ),
        url("../Assets/chap4.png");
      background-size: cover;
      background-position: center;
      // background-position: -50px 0;
    }
  }

  .chap1:hover {
    width: 100%;
  }
  .chap1:hover .left {
    background: $orange;
    width: 25%;
  }
  .chap1:hover .right {
    display: flex;
    width: 75%;
  }

  .chap2:hover {
    width: 100%;
  }
  .chap2:hover .left {
    background: $orange;
    width: 25%;
  }
  .chap2:hover .right {
    display: flex;
    width: 75%;
  }

  .chap3:hover {
    width: 100%;
  }
  .chap3:hover .left {
    background: $orange;
    width: 25%;
  }
  .chap3:hover .right {
    display: flex;
    width: 75%;
  }

  .chap4:hover {
    width: 100%;
  }
  .chap4:hover .left {
    width: 25%;
    background: $orange;
  }
  .chap4:hover .right {
    display: flex;
    width: 75%;
  }
  //   }
}

@media screen and (max-width: 768px) {
  .chapters:hover > .chapter:not(:hover) {
    width: auto;
  }

  .chapters {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: none;

    .chapter {
      width: 100%;
      height: fit-content;
      // transition: all 0.2s ease;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .left {
        height: 65vw;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // transition: all 0.2s ease;

        .details {
          display: flex;
          flex-direction: column;
          gap: 10px;
          h2 {
            font-size: 45px;
            color: white;
            text-align: center;
          }
          h3 {
            font-size: 35px;
            color: white;
            text-align: center;
          }
        }
      }

      .right {
        width: 100%;
        // display: none;
        height: 0;
        overflow: hidden;
        // display: flex;
        // flex-direction: column;
        // border: 2px solid red;
        // transition: all 0.2s ease;

        .imgBox {
          width: 100%;
          height: fit-content;
          max-height: 120vw;
          // border: 2px solid red;
          // transition: all 0.2s ease;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            // object-position: 0 -50px;
          }
        }

        .storyBox {
          background-color: black;
          color: white;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 30px 10px;
          // padding-right: 0;
          // transition: all 0.2s ease;

          .title {
            font-size: 30px;
            text-align: center;
            text-transform: uppercase;
            position: relative;

            &::before {
              content: "";
              width: 48px;
              height: 5px;
              top: -12px;
              left: 50%;
              transform: skew(-40deg) translateX(-50%);
              position: absolute;
              background-color: $orange;
            }
          }
          .desc {
            text-align: center;
            font-size: 18px;
            margin-top: 20px;
          }
        }
      }

      &.chap1 .left {
        background-image: linear-gradient(
            rgba(255, 90, 1, 0.6),
            rgba(255, 90, 1, 0.6)
          ),
          url("../Assets/chap1Mobile.png");
        background-size: cover;
        background-position: 0 0;
      }
      // &.chap1 .right .imgBox img {
      //   object-position: 0 -70px;
      // }
      &.chap2 .left {
        background-image: linear-gradient(
            rgba(255, 90, 1, 0.6),
            rgba(255, 90, 1, 0.6)
          ),
          url("../Assets/chap2Mobile.png");
        background-size: cover;
        background-position: 0 0;
      }
      // &.chap2 .right .imgBox img {
      //   object-position: 20px -50px;
      // }
      &.chap3 .left {
        background-image: linear-gradient(
            rgba(255, 90, 1, 0.6),
            rgba(255, 90, 1, 0.6)
          ),
          url("../Assets/chap3Mobile.png");
        background-size: cover;
        background-position: 0 0;
      }
      &.chap4 .left {
        background-image: linear-gradient(
            rgba(255, 90, 1, 0.6),
            rgba(255, 90, 1, 0.6)
          ),
          url("../Assets/chap4Mobile.png");
        background-size: cover;
        background-position: 0 0;

        // background-position: center;
      }
      // &.chap4 .right .imgBox img {
      //   object-position: 0;
      // }
    }

    .chap1:hover {
      width: initial;
    }
    .chap1:hover .left {
      background: default;
      width: initial;
    }

    .chap2:hover {
      width: initial;
    }
    .chap2:hover .left {
      background: default;
      width: initial;
    }

    .chap3:hover {
      width: initial;
    }
    .chap3:hover .left {
      background: default;
      width: initial;
    }

    .chap4:hover {
      width: initial;
    }
    .chap4:hover .left {
      background: default;
      width: initial;
    }

    .chapter {
      &.hovered .left {
        background: $orange;
      }

      &.hovered .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: initial;
        height: fit-content;
      }
    }
  }
}
