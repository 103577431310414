@import "./variables";

.coreValues {
  background: $mainBg;
  height: fit-content;
  width: 100%;
  color: white;
  padding: 70px 100px;
  display: flex;
  flex-direction: column;
  position: relative;

  .title {
    font-size: 35px;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;

    &::before {
      content: "";
      width: 48px;
      height: 5px;
      top: -12px;
      left: 0;
      transform: skew(-40deg);
      position: absolute;
      background-color: $orange;
    }
  }
  .desc {
    width: 65%;
    font-size: 18px;
  }

  .values {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    // gap: 10px;
    .value {
      width: 300px;
      // border: 2px solid red;
      position: relative;

      &::after {
        content: "";
        width: 48px;
        height: 5px;
        bottom: -30px;
        left: 20px;
        transform: skew(-40deg);
        position: absolute;
        background-color: $orange;
      }
      img {
        height: 150px;
        width: 150px;
      }
      .valueTitle {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 10px;
        text-transform: uppercase;
        margin-left: 20px;
      }
      .valueDesc {
        font-size: 18px;
        margin-left: 20px;
      }
    }
  }

  .learnmore {
    align-self: center;
    margin-left: 0;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $orange;
    color: $white;
    border: none;
    padding: 13px 40px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    ion-icon {
      font-size: 20px;
      margin-left: 10px;
    }
  }

  .coreValueBgFont {
    position: absolute;
    top: 70px;
    right: 50px;
    display: flex;
    justify-content: flex-end;
    // border: 2px solid red;

    img {
      width: 80%;
      // border: 2px solid red;
    }
  }
}

@media screen and (max-width: 768px) {
  .coreValues {
    background: $mainBg;
    height: fit-content;
    width: 100%;
    color: white;
    padding: 30px 10px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    position: relative;

    .title {
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 20px;
      position: relative;
      text-align: center;

      &::before {
        content: "";
        width: 48px;
        height: 5px;
        top: -12px;
        left: 50%;
        transform: skew(-40deg) translateX(-50%);
        position: absolute;
        background-color: $orange;
      }
    }
    .desc {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }

    .values {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;
      .value {
        width: 100%;
        // border: 2px solid red;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &::after {
          content: "";
          width: 48px;
          height: 5px;
          bottom: -12px;
          left: 50%;
          transform: skew(-40deg) translateX(-50%);
          position: absolute;
          background-color: $orange;
        }
        img {
          height: 170px;
          width: 170px;
          // border: 2px solid red;
        }
        .valueTitle {
          font-size: 25px;
          font-weight: 500;
          margin-bottom: 10px;
          text-transform: uppercase;
          margin-left: 0;
        }
        .valueDesc {
          font-size: 18px;
          margin-left: 20px;
          text-align: center;
        }
      }
    }

    .learnmore {
      align-self: center;
      margin-left: 0;
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $orange;
      color: $white;
      border: none;
      padding: 13px 30px;
      font-size: 16px;
      cursor: pointer;
    }

    .coreValueBgFont {
      display: none;
    }
  }
}
