@import "./variables";

.adminlogin {
  // border: 2px solid red;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .left {
    height: 100%;
    width: 50%;
    background: url("../Assets/loginBg.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .layer {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
    }

    .adminLogo {
      z-index: 5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 250px;
      pointer-events: none;
    }
  }
  .right {
    background-color: $mainBg;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    a > img {
      // border: 2px solid red;
      height: 100px;
      margin-top: 80px;
    }

    .loginBox {
      // border: 2px solid red;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $drop;
      padding: 20px 20px;
      width: 380px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      min-height: 200px;

      .tokenError {
        color: white;
        margin: auto;
        text-align: center;
        font-weight: 300;
        font-size: 25px;
      }

      .title {
        font-size: 25px;
        font-weight: 300;
        color: white;
        text-align: center;
        margin-bottom: 20px;
      }

      .instruction {
        text-align: center;
        margin-bottom: 10px;
        color: white;
      }

      form {
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
          width: 100%;
          margin-bottom: 15px;
          padding: 10px;
          background: none;
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          outline: none;

          &::placeholder {
            color: white;
          }
        }

        .resMsg {
          margin-top: 0;
        }

        button {
          cursor: pointer;
          padding: 10px;
          width: 100%;
          font-weight: 500;
          background-color: $orange;
          border: none;
          color: white;
          border-radius: 5px;
          outline: none;
          margin-top: 10px;
          // border: 2px solid red;
        }
      }

      .subLink {
        color: $orange;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
}
