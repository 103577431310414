@import "./variables";

.adminTop {
  display: flex;
  justify-content: flex-end;
  //   border: 2px solid red;
  background: $drop;
  //   padding: 10px 0;
  align-items: center;
  height: 60px;

  .profile {
    // border: 2px solid red;
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;

    .userImage {
      border: 2px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border-radius: 50%;
      padding: 5px;

      ion-icon {
        font-size: 25px;
      }
    }

    .title {
      font-weight: 300;
      font-size: 20px;
    }
  }
}

.adminMainContent {
  display: flex;
  height: calc(100vh - 60px);
  width: 100vw;
  overflow: hidden;

  .adminSide {
    width: 200px;
    background: $drop;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    // border: 2px solid red;
    color: white;

    .logo {
      //   border: 2px solid red;
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      img {
        height: 60px;
      }
    }

    .menus {
      // border: 2px solid red;
      width: 100%;
      height: calc(100vh - 100px);
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .subMenu {
        list-style: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        gap: 10px;
        margin-bottom: 10px;
        .link {
          // border: 2px solid red;
          width: fit-content;
          justify-content: center;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          color: white;
          text-decoration: none;
          font-size: 18px;
          cursor: pointer;
          opacity: 0.5;
          transition: opacity 0.3s linear;

          &:hover {
            opacity: 1;
          }

          &.menuActive {
            opacity: 1;
          }

          ion-icon {
            margin-right: 5px;
            font-size: 20px;
          }
        }
        .subLink {
          text-decoration: none;
          color: white;
          margin-left: 25px;
          // display: none;
          font-size: 16px;
          opacity: 0.5;
          transition: opacity 0.3s ease;

          &:hover {
            opacity: 1;
          }

          &.active {
            opacity: 1;
          }
          //   color: red;
        }
      }
    }
  }
}
