@import "./variables";

.founder {
  background-color: $mainBg;
  // border: 2px solid red;
  color: white;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .title {
    font-size: 55px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: -25px;
    z-index: 2;
    margin-left: 100px;
  }

  .imgBox {
    // border: 2px solid red;
    width: 100vw;
    display: flex;
    justify-content: center;
    position: relative;
    > img {
      margin-left: 50px;
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
      height: 650px;
      z-index: 1;
    }

    .backgroundFonts {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        // width: 500px;
        height: 95px;
      }
    }
  }
  .founderName {
    margin-top: 10px;
    margin-right: 100px;
    font-size: 55px;
  }
  .desc {
    text-align: center;
    width: 65%;
    margin-top: 20px;
    font-size: 22px;
  }

  .learnmore {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $orange;
    color: $white;
    border: none;
    padding: 13px 40px;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    ion-icon {
      font-size: 20px;
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .founder {
    background-color: $mainBg;
    // border: 2px solid red;
    color: white;
    padding: 10px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title {
      font-size: 22px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 5px;
      z-index: 2;
      margin-left: 0;
    }

    .imgBox {
      // border: 2px solid red;
      width: 100vw;
      display: flex;
      justify-content: center;
      position: relative;
      > img {
        margin-left: 0;
        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
        height: 300px;
        z-index: 1;
      }

      .backgroundFonts {
        display: none;
      }
    }
    .founderName {
      margin-top: 10px;
      font-size: 25px;
      font-weight: 500;
      margin-right: 0;
    }
    .desc {
      text-align: center;
      padding: 0 10px;
      width: 100%;
      margin-top: 20px;
      font-size: 18px;
    }

    .learnmore {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $orange;
      color: $white;
      border: none;
      padding: 13px 40px;
      font-size: 16px;
      cursor: pointer;
      clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

      ion-icon {
        font-size: 20px;
        margin-left: 10px;
      }
    }
  }
}
