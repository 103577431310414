@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");
.navbar {
  width: 100%;
  height: 130px;
  background-color: #222222;
  display: flex;
  align-items: center;
  top: 0;
  padding: 0 100px;
}
.navbar .leftNav {
  width: 50%;
  display: flex;
  align-items: center;
}
.navbar .leftNav img {
  height: 70px;
}
.navbar .rightNav {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 40px;
}
.navbar .rightNav .btns .navBtn {
  padding: 15px 45px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
}
.navbar .rightNav .btns .navBtn.joinNow {
  -webkit-clip-path: polygon(15% 0%, 100% 0%, 85.5% 100%, 0% 100%);
          clip-path: polygon(15% 0%, 100% 0%, 85.5% 100%, 0% 100%);
  background: #ffffff;
  color: #ff5a01;
}
.navbar .rightNav .btns .navBtn.freeClass {
  padding: 15px 35px;
  -webkit-clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
          clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
  margin-left: -23.5px;
  background: #ff5a01;
  color: #ffffff;
}
.navbar .rightNav .btns.servicePage .joinNow {
  display: none;
}
.navbar .rightNav .btns.servicePage .freeClass {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
}
.navbar .rightNav .menus {
  margin-top: 20px;
}
.navbar .rightNav .menus ul {
  display: flex;
  list-style: none;
  gap: 30px;
}
.navbar .rightNav .menus ul li {
  position: relative;
}
.navbar .rightNav .menus ul li a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
}
.navbar .rightNav .menus ul li.active::after {
  content: "";
  width: 48px;
  height: 5px;
  bottom: -12px;
  left: 0;
  transform: skew(-40deg);
  position: absolute;
  background-color: #ff5a01;
}

.mobileNavbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: none;
  }
  .mobileNavbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #222222;
  }
  .mobileNavbar .topNavbar {
    padding: 20px 0;
  }
  .mobileNavbar .topNavbar img {
    height: 70px;
  }
  .mobileNavbar .bottomNavbar {
    padding-bottom: 20px;
  }
  .mobileNavbar .bottomNavbar .navBtn {
    padding: 15px 45px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;
  }
  .mobileNavbar .bottomNavbar .navBtn.joinNow {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 85.5% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 85.5% 100%, 0% 100%);
    background: #ffffff;
    color: #ff5a01;
  }
  .mobileNavbar .bottomNavbar .navBtn.freeClass {
    padding: 15px 35px;
    -webkit-clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
    margin-left: -23.5px;
    background: #ff5a01;
    color: #ffffff;
  }
  .mobileNavbar .bottomNavbar.servicePageMobile .joinNow {
    display: none;
  }
  .mobileNavbar .bottomNavbar.servicePageMobile .freeClass {
    padding: 15px 55px;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    margin-left: 0;
  }
  .mobileNavbar .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
  }
  .mobileNavbar .hamburger .line {
    width: 35px;
    height: 3px;
    background: white;
    margin: 4.5px 0;
    transform-origin: left;
    transition: all 1.5s ease;
  }
  .mobileNavbar .hamburger.active .line2 {
    opacity: 0;
  }
  .mobileNavbar .hamburger.active .line1 {
    transform: rotate(45deg);
  }
  .mobileNavbar .hamburger.active .line3 {
    transform: rotate(-45deg);
  }
  .mobileNavbar .mobileMenu {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #222222;
    width: 100%;
    height: 100vh;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 1s ease;
  }
  .mobileNavbar .mobileMenu.activeMenu {
    transform: scaleY(1);
  }
  .mobileNavbar .mobileMenu ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ff5a01;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 30px;
    gap: 20px;
  }
  .mobileNavbar .mobileMenu ul li {
    text-align: center;
  }
  .mobileNavbar .mobileMenu ul li a {
    text-decoration: none;
    font-size: 25px;
    color: white;
    font-weight: 500;
  }
  .mobileNavbar .mobileMenu .menuBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    color: white;
  }
  .mobileNavbar .mobileMenu .menuBottom .menuSlogan {
    text-align: center;
    font-size: 20px;
  }
  .mobileNavbar .mobileMenu .menuBottom .shortLinks {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  .mobileNavbar .mobileMenu .menuBottom .shortLinks ion-icon {
    font-size: 30px;
    color: #ff5a01;
  }
  .mobileNavbar .mobileMenu .menuBottom .emailId {
    font-size: 20px;
    text-align: center;
  }
  .mobileNavbar .mobileMenu .menuBottom .emailId span {
    font-size: 22px;
    font-weight: 500;
  }
}
.homeHeader {
  background-color: #222222;
  height: 100vh;
  max-height: 60vw;
  width: 100%;
  display: flex;
  position: relative;
}
.homeHeader .navbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
}
.homeHeader .left {
  background: white;
  height: 100%;
  width: 55%;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 40% 100%, 0% 100%);
          clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 40% 100%, 0% 100%);
  background-size: 100%;
  background-repeat: no-repeat;
}
.homeHeader .left img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.homeHeader .right {
  width: 45%;
  height: 100%;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.homeHeader .right .slogan {
  color: #ffffff;
  margin-right: 100px;
  margin-left: 50px;
  margin-top: 27vh;
  text-align: left;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}
.homeHeader .right .desc {
  color: white;
  margin-right: 100px;
  margin-left: 50px;
  margin-top: 30px;
  font-size: 20px;
  text-align: left;
}
.homeHeader .right .learnmore {
  align-self: flex-start;
  margin-left: 50px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5a01;
  color: #ffffff;
  border: none;
  padding: 13px 40px;
  font-size: 16px;
  cursor: pointer;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.homeHeader .right .learnmore ion-icon {
  font-size: 20px;
  margin-left: 10px;
}
.homeHeader .backgroundFonts {
  position: absolute;
  bottom: 30px;
}
.homeHeader .backgroundFonts img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .homeHeader {
    background-color: #222222;
    height: auto;
    max-height: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .homeHeader .left {
    height: 100vw;
    width: 100%;
    -webkit-clip-path: none;
            clip-path: none;
    background-repeat: no-repeat;
  }
  .homeHeader .left img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .homeHeader .right {
    width: 100%;
    background-color: #222222;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px 10px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .homeHeader .right .slogan {
    color: #ffffff;
    padding: 0 5px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .homeHeader .right .desc {
    color: white;
    margin-right: 0;
    margin-left: 0;
    margin-top: 10px;
    padding: 0 5px;
    font-size: 17px;
    text-align: center;
  }
  .homeHeader .right .learnmore {
    align-self: center;
    margin-left: 0;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff5a01;
    color: #ffffff;
    border: none;
    padding: 13px 40px;
    font-size: 16px;
    cursor: pointer;
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .homeHeader .right .learnmore ion-icon {
    font-size: 20px;
    margin-left: 10px;
  }
  .homeHeader .backgroundFonts {
    display: none;
    position: relative;
    bottom: 30px;
  }
  .homeHeader .backgroundFonts img {
    width: 100%;
  }
}
.whoAreWe {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 100px 0;
  background-color: #222222;
  color: white;
  display: flex;
  position: relative;
}
.whoAreWe .left {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.whoAreWe .left .imgbox {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: 70px;
}
.whoAreWe .left .imgbox img {
  height: 550px;
  -o-object-fit: cover;
     object-fit: cover;
}
.whoAreWe .right {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.whoAreWe .right .title {
  font-size: 50px;
  margin-right: 100px;
  margin-left: 50px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}
.whoAreWe .right .title::before {
  content: "";
  width: 48px;
  height: 5px;
  top: -12px;
  left: 0;
  transform: skew(-40deg);
  position: absolute;
  background-color: #ff5a01;
}
.whoAreWe .right .desc {
  margin-left: 50px;
  margin-right: 100px;
  text-align: justify;
  line-height: 22px;
  font-size: 18px;
}
.whoAreWe .right .desc p {
  margin-top: 20px;
}
.whoAreWe .right .learnmore {
  align-self: flex-start;
  margin-left: 50px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5a01;
  color: #ffffff;
  border: none;
  padding: 13px 40px;
  font-size: 16px;
  cursor: pointer;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.whoAreWe .right .learnmore ion-icon {
  font-size: 20px;
  margin-left: 10px;
}
.whoAreWe .whoareweBgFontMobile {
  display: none;
}
.whoAreWe .whoareweBgFont {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 80px;
}
.whoAreWe .whoareweBgFont img {
  height: 550px;
}

@media screen and (max-width: 768px) {
  .whoAreWe {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
    background-color: #222222;
    color: white;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .whoAreWe .left {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: flex-end;
  }
  .whoAreWe .left .imgbox {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-right: 0;
  }
  .whoAreWe .left .imgbox img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .whoAreWe .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 10px;
  }
  .whoAreWe .right .title {
    font-size: 30px;
    margin-right: 0;
    margin-left: 0;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    text-align: center;
  }
  .whoAreWe .right .title::before {
    content: "";
    width: 48px;
    height: 5px;
    top: -12px;
    left: 50%;
    transform: skew(-40deg) translateX(-50%);
    position: absolute;
    background-color: #ff5a01;
  }
  .whoAreWe .right .desc {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    line-height: 22px;
    font-size: 16px;
    padding: 0 10px;
  }
  .whoAreWe .right .desc p {
    margin-top: 20px;
  }
  .whoAreWe .right .learnmore {
    align-self: center;
    margin-left: 0;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff5a01;
    color: #ffffff;
    border: none;
    padding: 13px 40px;
    font-size: 16px;
    cursor: pointer;
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .whoAreWe .right .learnmore ion-icon {
    font-size: 20px;
    margin-left: 10px;
  }
  .whoAreWe .whoareweBgFont {
    display: none;
  }
  .whoAreWe .whoareweBgFontMobile {
    display: block;
    margin: 10px 0;
    margin-right: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whoAreWe .whoareweBgFontMobile img {
    height: 40px;
  }
}
.highLight {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow-x: hidden !important;
  width: 100%;
  background-color: #222222;
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 50px;
}
.highLight .container {
  height: 550px;
  width: 100%;
  background-size: cover;
  background-image: url("../Assets/highlight.png");
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url("../Assets/highlight.png");
  -webkit-clip-path: polygon(0% 15%, 100% 0%, 100% 85%, 0% 100%);
          clip-path: polygon(0% 15%, 100% 0%, 100% 85%, 0% 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
}
.highLight .container .line {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  width: 2px;
  top: 0;
  left: 0;
  transform: rotate(15deg);
}
.highLight .container .line.line1 {
  left: 20%;
}
.highLight .container .line.line2 {
  left: 50%;
}
.highLight .container .line.line3 {
  left: 80%;
}
.highLight .container .details {
  display: flex;
  gap: 200px;
}
.highLight .container .details .detailItem {
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.highLight .container .details .detailItem h2 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 10px;
}
.highLight .container .details .detailItem h3 {
  text-align: center;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .highLight {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow-x: hidden !important;
    width: 100% auto;
    background-color: #222222;
    display: flex;
    align-items: center;
    position: relative;
  }
  .highLight .container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url("../Assets/highlight3.png");
    background-repeat: no-repeat;
    -webkit-clip-path: none;
            clip-path: none;
    display: flex;
    justify-content: center;
    color: white;
  }
  .highLight .container .line {
    height: 87%;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    width: 2px;
    top: 0;
    left: 0;
    transform: rotate(15deg);
  }
  .highLight .container .line.line1 {
    left: 10%;
  }
  .highLight .container .line.line2 {
    left: 47%;
  }
  .highLight .container .line.line3 {
    left: 85%;
  }
  .highLight .container .details {
    display: flex;
    gap: 40px;
    flex-direction: column;
    padding: 40px 10px;
  }
  .highLight .container .details .detailItem {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .highLight .container .details .detailItem h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .highLight .container .details .detailItem h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
  }
}
.ourServices {
  background-color: #222222;
  color: white;
  height: 100vh;
  width: 100%;
  position: relative;
}
.ourServices .serviceDetailBox {
  width: 30%;
  position: absolute;
  top: 50px;
  left: 100px;
  z-index: 5;
}
.ourServices .serviceDetailBox h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.ourServices .serviceDetailBox .desc {
  font-size: 18px;
  text-align: justify;
}
.ourServices .serviceContainer {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 0;
}
.ourServices .serviceContainer .slider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 150px;
}
.ourServices .serviceContainer .slider .preAllSlides {
  height: 400px;
  width: 300px;
  position: relative;
  margin-bottom: -200px;
}
.ourServices .serviceContainer .slider .preAllSlides .slide {
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(1px);
  transition: opacity 0.5s linear;
  opacity: 0;
}
.ourServices .serviceContainer .slider .preAllSlides .slide .slideDesc {
  display: none;
}
.ourServices .serviceContainer .slider .preAllSlides .slide::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.ourServices .serviceContainer .slider .preAllSlides .slide.preSlide {
  opacity: 1;
}
.ourServices .serviceContainer .slider .preAllSlides .slide img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ourServices .serviceContainer .slider .preAllSlides .slide .slideDesc {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}
.ourServices .serviceContainer .slider .preAllSlides .slide .slideDesc p {
  text-align: center;
}
.ourServices .serviceContainer .slider .preAllSlides .slide.activeSlide {
  filter: none;
}
.ourServices .serviceContainer .slider .preAllSlides .slide.activeSlide::after {
  background-color: transparent;
}
.ourServices .serviceContainer .slider .preAllSlides .slide.activeSlide .slideDesc {
  display: block;
}
.ourServices .serviceContainer .slider .activeAllSlides {
  height: 400px;
  width: 300px;
  position: relative;
}
.ourServices .serviceContainer .slider .activeAllSlides .slide {
  position: absolute;
  opacity: 0;
  top: 0;
  transition: opacity 0.5s linear;
  left: 0;
}
.ourServices .serviceContainer .slider .activeAllSlides .slide .slideDesc {
  display: block;
}
.ourServices .serviceContainer .slider .activeAllSlides .slide.activeSlide {
  opacity: 1;
}
.ourServices .serviceContainer .slider .activeAllSlides .slide img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ourServices .serviceContainer .slider .activeAllSlides .slide .slideDesc {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}
.ourServices .serviceContainer .slider .activeAllSlides .slide .slideDesc p {
  text-align: center;
  margin: 0;
}
.ourServices .serviceContainer .slider .nextAllSlides {
  height: 400px;
  width: 300px;
  position: relative;
  margin-top: -200px;
}
.ourServices .serviceContainer .slider .nextAllSlides .slide {
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(1px);
  margin-bottom: -250px;
  opacity: 0;
  transition: opacity 0.5s linear;
}
.ourServices .serviceContainer .slider .nextAllSlides .slide .slideDesc {
  display: none;
}
.ourServices .serviceContainer .slider .nextAllSlides .slide::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.ourServices .serviceContainer .slider .nextAllSlides .slide.nextSlide {
  opacity: 1;
}
.ourServices .serviceContainer .slider .nextAllSlides .slide img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ourServices .serviceContainer .slider .nextAllSlides .slide .slideDesc {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}
.ourServices .serviceContainer .slider .nextAllSlides .slide .slideDesc p {
  text-align: center;
}
.ourServices .serviceContainer .slider .nextAllSlides .slide.activeSlide {
  filter: none;
}
.ourServices .serviceContainer .slider .nextAllSlides .slide.activeSlide::after {
  background-color: transparent;
}
.ourServices .serviceContainer .slider .nextAllSlides .slide.activeSlide .slideDesc {
  display: block;
}
.ourServices .serviceContainer .slideController {
  position: absolute;
  bottom: 100px;
  right: 100px;
  margin: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.ourServices .serviceContainer .slideController .controller {
  cursor: pointer;
}
.ourServices .serviceContainer .slideController .controller p {
  margin: 0;
  font-size: 25px;
  font-weight: 500;
  color: rgb(144, 144, 144);
  padding: 10px 0;
  transition: all 0.5s ease;
}
.ourServices .serviceContainer .slideController .controller p.active {
  border-bottom: 4px dashed #ff5a01;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
}
.ourServices .serviceContainer .slideController .controller:hover p {
  color: white;
}
.ourServices .serviceContainer .slideController .learnmore {
  align-self: flex-start;
  margin-left: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5a01;
  color: #ffffff;
  border: none;
  padding: 15px 40px;
  font-size: 16px;
  cursor: pointer;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  border-radius: 0;
}
.ourServices .serviceContainer .slideController .learnmore ion-icon {
  font-size: 20px;
  margin-left: 10px;
}
.ourServices .backgroundFontsMobile {
  display: none;
}
.ourServices .backgroundFonts {
  position: absolute;
  top: 50px;
  right: 250px;
}
.ourServices .backgroundFonts img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .ourServices {
    background-color: #222222;
    color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    position: relative;
    padding: 0;
  }
  .ourServices .serviceDetailBox {
    width: 100%;
    padding: 10px;
    position: static;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .ourServices .serviceDetailBox h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
  }
  .ourServices .serviceDetailBox h2::before {
    content: "";
    width: 48px;
    height: 5px;
    top: -12px;
    left: 50%;
    transform: skew(-40deg) translateX(-50%);
    position: absolute;
    background-color: #ff5a01;
  }
  .ourServices .serviceDetailBox .desc {
    font-size: 18px;
    text-align: center;
  }
  .ourServices .serviceContainer {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    margin-top: 20px;
  }
  .ourServices .serviceContainer .slider {
    all: initial;
  }
  .ourServices .serviceContainer .slider * {
    all: unset;
  }
  .ourServices .serviceContainer .slider {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    gap: 20px;
    overflow: hidden;
  }
  .ourServices .serviceContainer .slider .preAllSlides,
.ourServices .serviceContainer .slider .activeAllSlides,
.ourServices .serviceContainer .slider .nextAllSlides {
    width: 60vw;
    height: 85vw;
  }
  .ourServices .serviceContainer .slider .preAllSlides .slide,
.ourServices .serviceContainer .slider .activeAllSlides .slide,
.ourServices .serviceContainer .slider .nextAllSlides .slide {
    transition: opacity 0.5s linear;
  }
  .ourServices .serviceContainer .slider .preAllSlides {
    position: relative;
    margin: 0;
    margin-top: 100px;
  }
  .ourServices .serviceContainer .slider .preAllSlides .slide {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    filter: blur(1px);
  }
  .ourServices .serviceContainer .slider .preAllSlides .slide .slideDesc {
    display: none;
  }
  .ourServices .serviceContainer .slider .preAllSlides .slide.preSlide {
    opacity: 1;
  }
  .ourServices .serviceContainer .slider .preAllSlides .slide img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .ourServices .serviceContainer .slider .activeAllSlides {
    position: relative;
    margin-top: 50px;
  }
  .ourServices .serviceContainer .slider .activeAllSlides .slide {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }
  .ourServices .serviceContainer .slider .activeAllSlides .slide.activeSlide {
    opacity: 1;
  }
  .ourServices .serviceContainer .slider .activeAllSlides .slide .slideDesc {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ourServices .serviceContainer .slider .activeAllSlides .slide .slideDesc p {
    text-align: center;
    color: white;
    width: 100%;
    font-family: "Maven Pro", sans-serif !important;
  }
  .ourServices .serviceContainer .slider .activeAllSlides .slide img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .ourServices .serviceContainer .slider .nextAllSlides {
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
  }
  .ourServices .serviceContainer .slider .nextAllSlides .slide {
    position: absolute;
    height: 100%;
    width: 100%;
    filter: blur(1px);
    top: 0;
    left: 0;
    opacity: 0;
  }
  .ourServices .serviceContainer .slider .nextAllSlides .slide .slideDesc {
    display: none;
  }
  .ourServices .serviceContainer .slider .nextAllSlides .slide.nextSlide {
    opacity: 1;
  }
  .ourServices .serviceContainer .slider .nextAllSlides .slide img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .ourServices .serviceContainer .slideController {
    position: static;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .ourServices .serviceContainer .slideController .controller {
    cursor: pointer;
  }
  .ourServices .serviceContainer .slideController .controller p {
    font-size: 25px;
    font-weight: 500;
    color: rgb(144, 144, 144);
    padding: 5px 0;
    transition: all 0.5s ease;
    margin: 0;
  }
  .ourServices .serviceContainer .slideController .controller p.active {
    border-bottom: 3px dashed #ff5a01;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
  }
  .ourServices .serviceContainer .slideController .controller:hover p {
    color: white;
  }
  .ourServices .serviceContainer .slideController .learnmore {
    align-self: center;
    margin-left: 0;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff5a01;
    color: #ffffff;
    border: none;
    padding: 15px 35px;
    font-size: 16px;
    cursor: pointer;
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .ourServices .serviceContainer .slideController .learnmore ion-icon {
    font-size: 20px;
    margin-left: 10px;
  }
  .ourServices .backgroundFonts {
    display: none;
  }
  .ourServices .backgroundFontsMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
  }
  .ourServices .backgroundFontsMobile img {
    width: 100%;
  }
}
.testimonials {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  background-color: #222222;
  color: #ffffff;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}
.testimonials .title {
  margin: 0;
  font-size: 50px;
  margin-right: 100px;
  margin-left: 100px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}
.testimonials .title::before {
  content: "";
  width: 48px;
  height: 5px;
  top: -12px;
  left: 0;
  transform: skew(-40deg);
  position: absolute;
  background-color: #ff5a01;
}
.testimonials .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.testimonials .container .slider {
  width: 1300px;
  height: 450px;
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
}
.testimonials .container .slider .allSlides {
  display: flex;
  transition: all 0.7s ease;
}
.testimonials .container .slider .allSlides .slide {
  font-size: 200px;
  width: 1300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}
.testimonials .container .slider .allSlides .slide .left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.testimonials .container .slider .allSlides .slide .left img {
  width: 70%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.testimonials .container .slider .allSlides .slide .right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;
  padding-right: 50px;
  padding-left: 50px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.testimonials .container .slider .allSlides .slide .right .desc {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: left;
}
.testimonials .container .slider .allSlides .slide .right .username {
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;
}
.testimonials .container .slider .allSlides .slide .right .viewClass {
  color: #ff5a01;
  font-size: 22px;
  margin-top: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.testimonials .container .slider .allSlides .slide .right .viewClass ion-icon {
  margin-left: 10px;
  font-size: 20px;
}
.testimonials .container .slider .allSlides.active0 {
  transform: translateX(0);
}
.testimonials .container .slider .allSlides.active1 {
  transform: translateX(-1300px);
}
.testimonials .container .slider .allSlides.active2 {
  transform: translateX(-2600px);
}
.testimonials .container .slider .allSlides.active3 {
  transform: translateX(-3900px);
}
.testimonials .container .slider .allSlides.active4 {
  transform: translateX(-5200px);
}
.testimonials .container .slider .allSlides.active5 {
  transform: translateX(-6500px);
}
.testimonials .container .slider .allSlides.active6 {
  transform: translateX(-7800px);
}
.testimonials .container .slideController {
  display: flex;
}
.testimonials .container .slideController .dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ff5a01;
  margin: 10px 5px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.testimonials .container .slideController .active {
  width: 30px;
  border-radius: 30px;
}
.testimonials .backgroundFont {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  right: 50px;
  top: 20px;
}
.testimonials .backgroundFont img {
  width: 70%;
}

@media screen and (max-width: 768px) {
  .testimonials {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-color: #222222;
    color: #ffffff;
    padding: 20px 10px;
    padding-top: 70px;
    position: relative;
  }
  .testimonials .title {
    margin: 0;
    font-size: 28px;
    margin-right: 0;
    margin-left: 0;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    text-align: center;
  }
  .testimonials .title::before {
    content: "";
    width: 48px;
    height: 5px;
    top: -12px;
    left: 50%;
    transform: skew(-40deg) translateX(-50%);
    position: absolute;
    background-color: #ff5a01;
  }
  .testimonials .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .testimonials .container .slider {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    overflow: hidden;
    justify-content: flex-start;
  }
  .testimonials .container .slider .allSlides {
    display: flex;
    gap: 5vw;
    justify-content: center;
    transition: all 0.7s ease;
  }
  .testimonials .container .slider .allSlides .slide {
    width: 95vw;
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .testimonials .container .slider .allSlides .slide .left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
  .testimonials .container .slider .allSlides .slide .left img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .testimonials .container .slider .allSlides .slide .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    margin: 0;
  }
  .testimonials .container .slider .allSlides .slide .right .desc {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .testimonials .container .slider .allSlides .slide .right .username {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
  }
  .testimonials .container .slider .allSlides .slide .right .viewClass {
    color: #ff5a01;
    font-size: 22px;
    margin-top: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .testimonials .container .slider .allSlides .slide .right .viewClass ion-icon {
    margin-left: 10px;
    font-size: 20px;
  }
  .testimonials .container .slider .allSlides.active0 {
    transform: translateX(0);
  }
  .testimonials .container .slider .allSlides.active1 {
    transform: translateX(-100vw);
  }
  .testimonials .container .slider .allSlides.active2 {
    transform: translateX(-200vw);
  }
  .testimonials .container .slider .allSlides.active3 {
    transform: translateX(-300vw);
  }
  .testimonials .container .slider .allSlides.active4 {
    transform: translateX(-400vw);
  }
  .testimonials .container .slider .allSlides.active5 {
    transform: translateX(-500vw);
  }
  .testimonials .container .slider .allSlides.active6 {
    transform: translateX(-600vw);
  }
  .testimonials .container .slideController {
    display: flex;
  }
  .testimonials .container .slideController .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #ff5a01;
    margin: 10px 5px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .testimonials .container .slideController .active {
    width: 30px;
    border-radius: 30px;
  }
  .testimonials .backgroundFont {
    position: static;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .testimonials .backgroundFont img {
    width: 100%;
  }
}
.subscribe {
  background-color: #222222;
  width: 100%;
  padding: 30px 100px;
  color: white;
  display: flex;
}
.subscribe .left {
  width: 60%;
}
.subscribe .left p {
  font-size: 34px;
  font-weight: 500;
  width: 80%;
}
.subscribe .right {
  width: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.subscribe .right .container {
  border: 1px solid white;
  display: flex;
  align-items: center;
  height: 62px;
}
.subscribe .right .container img {
  height: 60px;
  width: 60px;
}
.subscribe .right .container input {
  background-color: #222222;
  outline: none;
  border: none;
  padding: 10px;
  width: 350px;
  color: white;
  font-size: 18px;
}
.subscribe .right .container input::-moz-placeholder {
  color: rgb(178, 178, 178);
  text-transform: uppercase;
}
.subscribe .right .container input:-ms-input-placeholder {
  color: rgb(178, 178, 178);
  text-transform: uppercase;
}
.subscribe .right .container input::placeholder {
  color: rgb(178, 178, 178);
  text-transform: uppercase;
}
.subscribe .right .container button {
  padding: 10px;
  height: 60px;
  width: 60px;
  cursor: pointer;
  background-color: #ff5a01;
  color: white;
  border: none;
  font-weight: 500;
}
.subscribe .right .container button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .subscribe {
    background-color: #222222;
    width: 100%;
    padding: 30px 10px;
    color: white;
    display: flex;
    flex-direction: column;
  }
  .subscribe .left {
    width: 100%;
  }
  .subscribe .left p {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    width: 100%;
  }
  .subscribe .right {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subscribe .right .container {
    border: 1px solid white;
    display: flex;
    align-items: center;
    height: 62px;
  }
  .subscribe .right .container img {
    height: 60px;
    width: 60px;
  }
  .subscribe .right .container input {
    background-color: #222222;
    outline: none;
    border: none;
    padding: 10px;
    width: 60vw;
    color: white;
    font-size: 18px;
  }
  .subscribe .right .container input::-moz-placeholder {
    color: rgb(178, 178, 178);
    text-transform: uppercase;
  }
  .subscribe .right .container input:-ms-input-placeholder {
    color: rgb(178, 178, 178);
    text-transform: uppercase;
  }
  .subscribe .right .container input::placeholder {
    color: rgb(178, 178, 178);
    text-transform: uppercase;
  }
  .subscribe .right .container button {
    padding: 10px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    background-color: #ff5a01;
    color: white;
    border: none;
    font-weight: 500;
  }
}
.overview {
  background-color: #222222;
  padding-top: 30px;
  overflow-x: hidden !important;
  width: 100%;
  padding-bottom: 10px;
}
.overview .slideImg img {
  width: 100%;
  height: auto;
}
.overview .insta {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.overview .insta .instaLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #ff5a01;
  font-weight: 500;
  text-decoration: none;
}
.overview .insta .instaLink ion-icon {
  margin-right: 10px;
  font-size: 30px;
}

@media screen and (max-width: 768px) {
  .overview {
    background-color: #222222;
    padding-top: 10px;
    overflow-x: hidden !important;
    width: 100%;
    padding-bottom: 10px;
  }
  .overview .slideImg {
    margin-left: 0;
  }
  .overview .slideImg img {
    width: 100%;
  }
  .overview .insta {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  .overview .insta .instaLink {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #ff5a01;
    font-weight: 500;
    justify-content: center;
  }
  .overview .insta .instaLink i {
    margin-right: 10px;
    font-size: 20px;
  }
}
.footer {
  background-color: #222222;
  color: white;
  display: flex;
  align-items: center;
  padding: 50px 150px;
  height: 300px;
}
.footer .left,
.footer .center,
.footer .right {
  height: 100%;
}
.footer .left {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.footer .left img {
  height: 150px;
  margin-bottom: 10px;
}
.footer .left p {
  font-size: 20px;
  font-weight: 500;
}
.footer .left p span {
  font-weight: 300;
}
.footer .center {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 100px;
  width: 55%;
}
.footer .center ul {
  list-style: none;
  display: flex;
  gap: 30px;
}
.footer .center ul li {
  text-align: center;
  margin: 10px 0;
}
.footer .center ul li a {
  text-decoration: none;
  color: white;
  font-weight: 300;
  font-size: 18px;
}
.footer .center .socialIcons {
  display: flex;
  gap: 30px;
}
.footer .center .socialIcons a {
  border: 2px solid white;
  font-size: 25px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
}
.footer .center .branding {
  font-size: 18px;
  color: white;
  text-decoration: none;
}
.footer .right {
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  padding-top: 10px;
}
.footer .right .detail {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}
.footer .right .detail a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 768px) {
  .footer {
    background-color: #222222;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .footer .left,
.footer .center,
.footer .right {
    width: 100%;
  }
  .footer .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .footer .left img {
    height: 130px;
    margin-bottom: 10px;
  }
  .footer .left p {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  .footer .left p span {
    font-weight: 300;
  }
  .footer .center {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0;
    width: 100%;
  }
  .footer .center ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 10px;
  }
  .footer .center ul li {
    text-align: center;
    margin: 10px 0;
  }
  .footer .center ul li a {
    text-decoration: none;
    color: white;
    font-weight: 300;
    font-size: 20px;
  }
  .footer .center .socialIcons {
    display: flex;
    gap: 30px;
    margin: 10px 0;
  }
  .footer .center .socialIcons a {
    border: 2px solid white;
    font-size: 25px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
  }
  .footer .center .branding {
    font-size: 20px;
    margin-top: 10px;
  }
  .footer .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-top: 10px;
  }
  .footer .right .detail {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}
.chapters:hover > .chapter:not(:hover) {
  width: 0;
}

.chapters {
  height: calc(100vh - 130px);
  max-height: 600px;
  width: 100%;
  display: flex;
  overflow: hidden;
}
.chapters .chapter {
  width: 25%;
  height: 100%;
  overflow: hidden;
  display: flex;
}
.chapters .chapter .left {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chapters .chapter .left .details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.chapters .chapter .left .details h2 {
  font-size: 45px;
  color: white;
  text-align: center;
}
.chapters .chapter .left .details h3 {
  font-size: 35px;
  color: white;
  text-align: center;
}
.chapters .chapter .right {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 75%;
  display: none;
}
.chapters .chapter .right .imgBox {
  width: 45%;
  height: 100%;
  overflow: hidden;
}
.chapters .chapter .right .imgBox img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chapters .chapter .right .storyBox {
  background-color: black;
  color: white;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 40px;
  padding-right: 70px;
}
.chapters .chapter .right .storyBox .title {
  font-size: 45px;
  text-transform: uppercase;
  position: relative;
}
.chapters .chapter .right .storyBox .title::before {
  content: "";
  width: 48px;
  height: 5px;
  top: -12px;
  left: 0;
  transform: skew(-40deg);
  position: absolute;
  background-color: #ff5a01;
}
.chapters .chapter .right .storyBox .desc {
  text-align: left;
  font-size: 18px;
  margin-top: 20px;
}
.chapters .chapter.chap1 .left {
  background-image: linear-gradient(rgba(255, 90, 1, 0.6), rgba(255, 90, 1, 0.6)), url("../Assets/chap1.png");
  background-size: cover;
  background-position: center;
}
.chapters .chapter.chap2 .left {
  background-image: linear-gradient(rgba(255, 90, 1, 0.6), rgba(255, 90, 1, 0.6)), url("../Assets/chap2.png");
  background-size: cover;
  background-position: center;
}
.chapters .chapter.chap3 .left {
  background-image: linear-gradient(rgba(255, 90, 1, 0.6), rgba(255, 90, 1, 0.6)), url("../Assets/chap3.png");
  background-size: cover;
  background-position: center;
}
.chapters .chapter.chap4 .left {
  background-image: linear-gradient(rgba(255, 90, 1, 0.6), rgba(255, 90, 1, 0.6)), url("../Assets/chap4.png");
  background-size: cover;
  background-position: center;
}
.chapters .chap1:hover {
  width: 100%;
}
.chapters .chap1:hover .left {
  background: #ff5a01;
  width: 25%;
}
.chapters .chap1:hover .right {
  display: flex;
  width: 75%;
}
.chapters .chap2:hover {
  width: 100%;
}
.chapters .chap2:hover .left {
  background: #ff5a01;
  width: 25%;
}
.chapters .chap2:hover .right {
  display: flex;
  width: 75%;
}
.chapters .chap3:hover {
  width: 100%;
}
.chapters .chap3:hover .left {
  background: #ff5a01;
  width: 25%;
}
.chapters .chap3:hover .right {
  display: flex;
  width: 75%;
}
.chapters .chap4:hover {
  width: 100%;
}
.chapters .chap4:hover .left {
  width: 25%;
  background: #ff5a01;
}
.chapters .chap4:hover .right {
  display: flex;
  width: 75%;
}

@media screen and (max-width: 768px) {
  .chapters:hover > .chapter:not(:hover) {
    width: auto;
  }
  .chapters {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: none;
  }
  .chapters .chapter {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .chapters .chapter .left {
    height: 65vw;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chapters .chapter .left .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .chapters .chapter .left .details h2 {
    font-size: 45px;
    color: white;
    text-align: center;
  }
  .chapters .chapter .left .details h3 {
    font-size: 35px;
    color: white;
    text-align: center;
  }
  .chapters .chapter .right {
    width: 100%;
    height: 0;
    overflow: hidden;
  }
  .chapters .chapter .right .imgBox {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 120vw;
    overflow: hidden;
  }
  .chapters .chapter .right .imgBox img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .chapters .chapter .right .storyBox {
    background-color: black;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 10px;
  }
  .chapters .chapter .right .storyBox .title {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
  }
  .chapters .chapter .right .storyBox .title::before {
    content: "";
    width: 48px;
    height: 5px;
    top: -12px;
    left: 50%;
    transform: skew(-40deg) translateX(-50%);
    position: absolute;
    background-color: #ff5a01;
  }
  .chapters .chapter .right .storyBox .desc {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  .chapters .chapter.chap1 .left {
    background-image: linear-gradient(rgba(255, 90, 1, 0.6), rgba(255, 90, 1, 0.6)), url("../Assets/chap1Mobile.png");
    background-size: cover;
    background-position: 0 0;
  }
  .chapters .chapter.chap2 .left {
    background-image: linear-gradient(rgba(255, 90, 1, 0.6), rgba(255, 90, 1, 0.6)), url("../Assets/chap2Mobile.png");
    background-size: cover;
    background-position: 0 0;
  }
  .chapters .chapter.chap3 .left {
    background-image: linear-gradient(rgba(255, 90, 1, 0.6), rgba(255, 90, 1, 0.6)), url("../Assets/chap3Mobile.png");
    background-size: cover;
    background-position: 0 0;
  }
  .chapters .chapter.chap4 .left {
    background-image: linear-gradient(rgba(255, 90, 1, 0.6), rgba(255, 90, 1, 0.6)), url("../Assets/chap4Mobile.png");
    background-size: cover;
    background-position: 0 0;
  }
  .chapters .chap1:hover {
    width: initial;
  }
  .chapters .chap1:hover .left {
    background: default;
    width: initial;
  }
  .chapters .chap2:hover {
    width: initial;
  }
  .chapters .chap2:hover .left {
    background: default;
    width: initial;
  }
  .chapters .chap3:hover {
    width: initial;
  }
  .chapters .chap3:hover .left {
    background: default;
    width: initial;
  }
  .chapters .chap4:hover {
    width: initial;
  }
  .chapters .chap4:hover .left {
    background: default;
    width: initial;
  }
  .chapters .chapter.hovered .left {
    background: #ff5a01;
  }
  .chapters .chapter.hovered .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: initial;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
.coreValues {
  background: #222222;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  color: white;
  padding: 70px 100px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.coreValues .title {
  font-size: 35px;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
}
.coreValues .title::before {
  content: "";
  width: 48px;
  height: 5px;
  top: -12px;
  left: 0;
  transform: skew(-40deg);
  position: absolute;
  background-color: #ff5a01;
}
.coreValues .desc {
  width: 65%;
  font-size: 18px;
}
.coreValues .values {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.coreValues .values .value {
  width: 300px;
  position: relative;
}
.coreValues .values .value::after {
  content: "";
  width: 48px;
  height: 5px;
  bottom: -30px;
  left: 20px;
  transform: skew(-40deg);
  position: absolute;
  background-color: #ff5a01;
}
.coreValues .values .value img {
  height: 150px;
  width: 150px;
}
.coreValues .values .value .valueTitle {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  margin-left: 20px;
}
.coreValues .values .value .valueDesc {
  font-size: 18px;
  margin-left: 20px;
}
.coreValues .learnmore {
  align-self: center;
  margin-left: 0;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5a01;
  color: #ffffff;
  border: none;
  padding: 13px 40px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.coreValues .learnmore ion-icon {
  font-size: 20px;
  margin-left: 10px;
}
.coreValues .coreValueBgFont {
  position: absolute;
  top: 70px;
  right: 50px;
  display: flex;
  justify-content: flex-end;
}
.coreValues .coreValueBgFont img {
  width: 80%;
}

@media screen and (max-width: 768px) {
  .coreValues {
    background: #222222;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    color: white;
    padding: 30px 10px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .coreValues .title {
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
  }
  .coreValues .title::before {
    content: "";
    width: 48px;
    height: 5px;
    top: -12px;
    left: 50%;
    transform: skew(-40deg) translateX(-50%);
    position: absolute;
    background-color: #ff5a01;
  }
  .coreValues .desc {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }
  .coreValues .values {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }
  .coreValues .values .value {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .coreValues .values .value::after {
    content: "";
    width: 48px;
    height: 5px;
    bottom: -12px;
    left: 50%;
    transform: skew(-40deg) translateX(-50%);
    position: absolute;
    background-color: #ff5a01;
  }
  .coreValues .values .value img {
    height: 170px;
    width: 170px;
  }
  .coreValues .values .value .valueTitle {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    margin-left: 0;
  }
  .coreValues .values .value .valueDesc {
    font-size: 18px;
    margin-left: 20px;
    text-align: center;
  }
  .coreValues .learnmore {
    align-self: center;
    margin-left: 0;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff5a01;
    color: #ffffff;
    border: none;
    padding: 13px 30px;
    font-size: 16px;
    cursor: pointer;
  }
  .coreValues .coreValueBgFont {
    display: none;
  }
}
.founder {
  background-color: #222222;
  color: white;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.founder .title {
  font-size: 55px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: -25px;
  z-index: 2;
  margin-left: 100px;
}
.founder .imgBox {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
}
.founder .imgBox > img {
  margin-left: 50px;
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
          clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  height: 650px;
  z-index: 1;
}
.founder .imgBox .backgroundFonts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.founder .imgBox .backgroundFonts img {
  height: 95px;
}
.founder .founderName {
  margin-top: 10px;
  margin-right: 100px;
  font-size: 55px;
}
.founder .desc {
  text-align: center;
  width: 65%;
  margin-top: 20px;
  font-size: 22px;
}
.founder .learnmore {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5a01;
  color: #ffffff;
  border: none;
  padding: 13px 40px;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.founder .learnmore ion-icon {
  font-size: 20px;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .founder {
    background-color: #222222;
    color: white;
    padding: 10px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .founder .title {
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 5px;
    z-index: 2;
    margin-left: 0;
  }
  .founder .imgBox {
    width: 100vw;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .founder .imgBox > img {
    margin-left: 0;
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
            clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    height: 300px;
    z-index: 1;
  }
  .founder .imgBox .backgroundFonts {
    display: none;
  }
  .founder .founderName {
    margin-top: 10px;
    font-size: 25px;
    font-weight: 500;
    margin-right: 0;
  }
  .founder .desc {
    text-align: center;
    padding: 0 10px;
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
  }
  .founder .learnmore {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff5a01;
    color: #ffffff;
    border: none;
    padding: 13px 40px;
    font-size: 16px;
    cursor: pointer;
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .founder .learnmore ion-icon {
    font-size: 20px;
    margin-left: 10px;
  }
}
.trainers {
  display: flex;
  flex-direction: column;
  background-color: #222222;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 100px;
  width: 100%;
}
.trainers .trainerDetail {
  width: 100%;
  color: white;
  display: flex;
  position: relative;
  margin-bottom: 100px;
}
.trainers .trainerDetail .left {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.trainers .trainerDetail .left .imgbox {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: 70px;
}
.trainers .trainerDetail .left .imgbox img {
  height: 500px;
}
.trainers .trainerDetail .right {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.trainers .trainerDetail .right .title {
  font-size: 30px;
  margin-left: 30px;
  margin-bottom: 30px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}
.trainers .trainerDetail .right .title::before {
  content: "";
  width: 48px;
  height: 5px;
  top: -12px;
  left: 0;
  transform: skew(-40deg);
  position: absolute;
  background-color: #ff5a01;
}
.trainers .trainerDetail .right .desc {
  margin-left: 30px;
  margin-right: 100px;
  text-align: left;
  line-height: 22px;
  font-size: 18px;
}
.trainers .trainerDetail .right .desc p {
  margin-bottom: 40px;
}
.trainers .trainerDetail .right .learnmore {
  align-self: flex-start;
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5a01;
  color: #ffffff;
  border: none;
  padding: 13px 40px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.trainers .trainerDetail .right .learnmore ion-icon {
  font-size: 20px;
  margin-left: 10px;
}
.trainers .trainerDetail .personalTrainerMobile {
  display: none;
}
.trainers .trainerDetail .personalTrainerFont {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
}
.trainers .trainerDetail .personalTrainerFont img {
  width: 70%;
}
.trainers .trainerDetail:nth-child(2n+1) {
  flex-direction: row-reverse;
  position: relative;
}
.trainers .trainerDetail:nth-child(2n+1) .left {
  justify-content: flex-start;
}
.trainers .trainerDetail:nth-child(2n+1) .left .imgbox {
  margin-right: 100px;
}
.trainers .trainerDetail:nth-child(2n+1) .right {
  margin-right: 30px;
  margin-left: 100px;
}
.trainers .trainerDetail:nth-child(2n+1) .personalTrainerFont {
  position: absolute;
  top: 50%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transform: translateY(-50%);
  left: auto;
  right: 80px;
}

@media screen and (max-width: 768px) {
  .trainers {
    display: flex;
    flex-direction: column;
    background-color: #222222;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    width: 100%;
  }
  .trainers .trainerDetail {
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
    padding-top: 50px;
  }
  .trainers .trainerDetail .left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .trainers .trainerDetail .left .imgbox {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-right: 0;
  }
  .trainers .trainerDetail .left .imgbox img {
    height: auto;
    width: 100%;
  }
  .trainers .trainerDetail .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 30px 0;
  }
  .trainers .trainerDetail .right .title {
    font-size: 30px;
    margin-left: 0;
    margin-bottom: 30px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    position: relative;
  }
  .trainers .trainerDetail .right .title::before {
    content: "";
    width: 48px;
    height: 5px;
    top: -12px;
    left: 50%;
    transform: skew(-40deg) translateX(-50%);
    position: absolute;
    background-color: #ff5a01;
  }
  .trainers .trainerDetail .right .desc {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    line-height: 22px;
    font-size: 18px;
  }
  .trainers .trainerDetail .right .desc p {
    margin-bottom: 10px;
  }
  .trainers .trainerDetail .right .learnmore {
    align-self: center;
    margin-left: 0;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff5a01;
    color: #ffffff;
    border: none;
    padding: 13px 40px;
    font-size: 16px;
    cursor: pointer;
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .trainers .trainerDetail .right .learnmore ion-icon {
    font-size: 20px;
    margin-left: 10px;
  }
  .trainers .trainerDetail .personalTrainerFont {
    display: none;
  }
  .trainers .trainerDetail .personalTrainerMobile {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .trainers .trainerDetail .personalTrainerMobile img {
    width: 100%;
  }
  .trainers .trainerDetail:nth-child(2n+1) {
    flex-direction: column;
    position: relative;
  }
  .trainers .trainerDetail:nth-child(2n+1) .left {
    justify-content: flex-start;
  }
  .trainers .trainerDetail:nth-child(2n+1) .left .imgbox {
    margin-right: 0;
  }
  .trainers .trainerDetail:nth-child(2n+1) .right {
    margin-right: 0;
    margin-left: 0;
  }
  .trainers .trainerDetail:nth-child(2n+1) .personalTrainerFont {
    display: none;
  }
}
.quiz {
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 130px);
  width: 100%;
  padding-bottom: 130px;
  position: relative;
  color: white;
}
.quiz .title {
  font-size: 60px;
  margin-bottom: 20px;
}
.quiz .slogan {
  font-size: 40px;
  font-weight: 300;
}
.quiz .learnmore {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5a01;
  color: #ffffff;
  border: none;
  padding: 15px 50px;
  font-size: 18px;
  cursor: pointer;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.quiz .learnmore ion-icon {
  font-size: 20px;
  margin-left: 10px;
}
.quiz .questionBox {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.quiz .questionBox .question {
  font-size: 38px;
  font-weight: 300;
}
.quiz .questionBox .suggestion {
  font-size: 25px;
  font-weight: 300;
  margin-top: 5px;
}
.quiz .questionBox .checks {
  margin-top: 20px;
  flex-direction: column;
}
.quiz .questionBox .checks > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.quiz .questionBox .checks > div input {
  display: none;
}
.quiz .questionBox .checks > div input[type=radio]:checked + .box::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 15px;
  width: 15px;
  border-radius: 3px;
  background-color: #ff5a01;
}
.quiz .questionBox .checks > div .box {
  height: 22px;
  width: 22px;
  background-color: #222222;
  border: 2px solid #ff5a01;
  margin-right: 10px;
  border-radius: 5px;
  position: relative;
}
.quiz .questionBox .checks > div label {
  cursor: pointer;
}
.quiz .questionBox .checks > div .text {
  font-size: 28px;
  cursor: pointer;
}
.quiz .questionBox .dropDown {
  width: 400px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.quiz .questionBox .dropDown .dropDown-Btn {
  padding: 15px 20px;
  background: #2b2c33;
  border-radius: 5px;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.quiz .questionBox .dropDown .dropDown-content {
  position: absolute;
  top: 120%;
  left: 0;
  padding: 10px;
  background: #2b2c33;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 300;
  border-radius: 5px;
  color: white;
  z-index: 2;
  width: 100%;
}
.quiz .questionBox .dropDown .dropDown-content .dropDown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.quiz .questionBox .dropDown .dropDown-content .dropDown-item:hover {
  background-color: #222222;
}
.quiz .questionBox .btn {
  background-color: #ff5a01;
  padding: 12px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  border: none;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  z-index: 1;
}
.quiz .questionBox .btn ion-icon {
  margin-left: 5px;
}
.quiz .preNextBtns {
  position: absolute;
  bottom: 100px;
  right: 300px;
  display: flex;
  gap: 5px;
}
.quiz .preNextBtns button {
  background-color: #ff5a01;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quiz .preNextBtns button ion-icon {
  font-size: 20px;
  font-weight: 100;
}
.quiz .preNextBtns button:disabled {
  color: rgba(255, 152, 96, 0.9);
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .quiz {
    background-color: #222222;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding: 0 10px;
    padding-bottom: 0;
    position: relative;
    color: white;
  }
  .quiz .title {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  .quiz .slogan {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }
  .quiz .learnmore {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff5a01;
    color: #ffffff;
    border: none;
    padding: 13px 40px;
    font-size: 18px;
    cursor: pointer;
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .quiz .learnmore ion-icon {
    font-size: 20px;
    margin-left: 10px;
  }
  .quiz .questionBox {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .quiz .questionBox.activeQueBox {
    height: calc(100vh - 190px);
  }
  .quiz .questionBox .question {
    font-size: 25px;
    font-weight: 300;
    text-align: center;
    width: 80%;
  }
  .quiz .questionBox .suggestion {
    font-size: 20px;
    font-weight: 300;
    margin-top: 5px;
    text-align: center;
  }
  .quiz .questionBox .checks {
    margin-top: 10px;
    align-self: center;
    flex-direction: column;
  }
  .quiz .questionBox .checks > div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .quiz .questionBox .checks > div input {
    display: none;
  }
  .quiz .questionBox .checks > div input[type=radio]:checked + .box::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 12px;
    width: 12px;
    border-radius: 3px;
    background-color: #ff5a01;
  }
  .quiz .questionBox .checks > div .box {
    height: 20px;
    width: 20px;
    background-color: #222222;
    border: 2px solid #ff5a01;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
  }
  .quiz .questionBox .checks > div label {
    cursor: pointer;
  }
  .quiz .questionBox .checks > div .text {
    font-size: 24px;
    cursor: pointer;
  }
  .quiz .questionBox .dropDown {
    width: 80vw;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .quiz .questionBox .dropDown .dropDown-Btn {
    padding: 15px 20px;
    background: #2b2c33;
    border-radius: 5px;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 500;
    font-size: 18px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .quiz .questionBox .dropDown .dropDown-content {
    position: absolute;
    top: 120%;
    left: 0;
    padding: 10px;
    background: #2b2c33;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 300;
    border-radius: 5px;
    color: white;
    width: 100%;
  }
  .quiz .questionBox .dropDown .dropDown-content .dropDown-item {
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .quiz .questionBox .dropDown .dropDown-content .dropDown-item:hover {
    background-color: #222222;
  }
  .quiz .questionBox .btn {
    background-color: #ff5a01;
    align-self: center;
    padding: 12px 30px;
    color: white;
    outline: none;
    border: none;
    margin-top: 20px;
    border-radius: 0;
    cursor: pointer;
    font-size: 16px;
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .quiz .preNextBtns {
    display: none;
  }
}
.allplans {
  width: 100%;
  background-color: #222222;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: white;
}
.allplans .planContainer {
  padding: 0 150px;
  padding-bottom: 50px;
  position: relative;
}
.allplans .planContainer .planDetails {
  display: flex;
  position: relative;
  margin-bottom: 50px;
}
.allplans .planContainer .planDetails .suggestion {
  position: absolute;
  top: 50px;
  color: #ff5a01;
}
.allplans .planContainer .planDetails > .planLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
}
.allplans .planContainer .planDetails > .planLeft .serviceBgFontMobile {
  display: none;
}
.allplans .planContainer .planDetails > .planLeft > .title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 120px;
}
.allplans .planContainer .planDetails > .planLeft > .desc {
  width: 90%;
  text-align: left;
  margin-bottom: 30px;
  font-size: 18px;
}
.allplans .planContainer .planDetails > .planLeft a {
  text-decoration: none;
  width: 90%;
}
.allplans .planContainer .planDetails > .planLeft a > button {
  background-color: #ff5a01;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 40px;
  width: 100%;
}
.allplans .planContainer .planDetails > .planLeft a > button .btnTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.allplans .planContainer .planDetails > .planLeft a > button .btnTop .btnTitle {
  font-size: 18px;
  font-weight: 700;
}
.allplans .planContainer .planDetails > .planLeft a > button .btnTop .price {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1px;
}
.allplans .planContainer .planDetails > .planLeft a > button .btnTop .price span {
  font-size: 26px;
  font-weight: 600;
  margin-right: 5px;
}
.allplans .planContainer .planDetails > .planLeft a > button .btnTop .price .prev {
  font-size: 20px;
}
.allplans .planContainer .planDetails > .planLeft a > button .btnBottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.allplans .planContainer .planDetails > .planLeft a > button .btnBottom .btnDesc {
  font-size: 16px;
  font-weight: 300;
}
.allplans .planContainer .planDetails > .planLeft a > button .btnBottom .subPrice {
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 0;
}
.allplans .planContainer .planDetails > .planLeft a > button .btnBottom .subPrice .prev {
  font-size: 14px;
  margin-right: 10px;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .slotSlogan {
  font-size: 30px;
  font-weight: 300px;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot {
  display: flex;
  flex-direction: column;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .daySlot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .daySlot .dates {
  font-size: 20px;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .daySlot .btn {
  background-color: #ff5a01;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 8px 20px;
  width: 110px;
  cursor: pointer;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .daySlot .btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: space-between;
  transition: all 0.5s ease;
  flex-wrap: wrap;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot {
  display: flex;
  width: 48%;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot input {
  display: none;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot input[type=radio]:checked + .timeBox {
  background-color: #ff5a01;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot .timeBox {
  border: 2px solid #ff5a01;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 8px 5px;
  color: white;
  transition: all 0.5s ease;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot .timeBox:hover {
  background-color: #ff5a01;
}
.allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot .timeBox ion-icon {
  color: white;
  font-size: 25px;
  transition: all 0.5s ease;
}
.allplans .planContainer .planDetails > .planRight {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  padding-top: 160px;
}
.allplans .planContainer .planDetails > .planRight img {
  height: 500px;
}
.allplans .planContainer .serviceBgFont {
  position: absolute;
  top: 80px;
  left: 20px;
}
.allplans .planContainer .serviceBgFont img {
  width: 100%;
}
.allplans .planContainer:nth-child(2n) .planDetails {
  flex-direction: row-reverse;
}
.allplans .planContainer:nth-child(2n) .planDetails > .planLeft {
  align-items: flex-end;
}
.allplans .planContainer:nth-child(2n) .planDetails > .planRight {
  justify-content: flex-start;
}

@media screen and (max-width: 768px) {
  .allplans {
    padding: 10px;
    width: 100%;
    background-color: #222222;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
  }
  .allplans .planContainer {
    padding: 0;
    padding-bottom: 20px;
    position: relative;
  }
  .allplans .planContainer .planDetails {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    margin-bottom: 0;
    padding-top: 70px;
  }
  .allplans .planContainer .planDetails.newUserPlan {
    padding-top: 0;
  }
  .allplans .planContainer .planDetails .suggestion {
    position: absolute;
    top: 0;
    text-align: center;
    color: #ff5a01;
  }
  .allplans .planContainer .planDetails > .planLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 30px 0;
    padding-bottom: 0;
  }
  .allplans .planContainer .planDetails > .planLeft .serviceBgFontMobile {
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }
  .allplans .planContainer .planDetails > .planLeft .serviceBgFontMobile img {
    height: 30px;
  }
  .allplans .planContainer .planDetails > .planLeft > .title {
    font-size: 25px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 15px;
    position: relative;
  }
  .allplans .planContainer .planDetails > .planLeft > .title::before {
    content: "";
    width: 48px;
    height: 5px;
    top: -12px;
    left: 50%;
    transform: skew(-40deg) translateX(-50%);
    position: absolute;
    background-color: #ff5a01;
  }
  .allplans .planContainer .planDetails > .planLeft > .desc {
    width: 90%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .allplans .planContainer .planDetails > .planLeft a {
    text-decoration: none;
    width: 100%;
  }
  .allplans .planContainer .planDetails > .planLeft a > button {
    background-color: #ff5a01;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    padding: 10px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
  }
  .allplans .planContainer .planDetails > .planLeft a > button .btnTop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .allplans .planContainer .planDetails > .planLeft a > button .btnTop .btnTitle {
    font-size: 16px;
    font-weight: 700;
  }
  .allplans .planContainer .planDetails > .planLeft a > button .btnTop .price {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .allplans .planContainer .planDetails > .planLeft a > button .btnTop .price span {
    font-size: 22px;
    font-weight: 600;
    margin-right: 5px;
  }
  .allplans .planContainer .planDetails > .planLeft a > button .btnBottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .allplans .planContainer .planDetails > .planLeft a > button .btnBottom .btnDesc {
    font-size: 14px;
    font-weight: 300;
  }
  .allplans .planContainer .planDetails > .planLeft a > button .btnBottom .subPrice {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 0;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .slotSlogan {
    font-size: 22px;
    font-weight: 300;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot {
    display: flex;
    flex-direction: column;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .daySlot {
    display: flex;
    justify-content: space-between;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .daySlot .dates {
    font-size: 16px;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .daySlot .btn {
    background-color: #ff5a01;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 8px 20px;
    width: 110px;
    cursor: pointer;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .daySlot .btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot {
    display: flex;
    width: 48%;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot input {
    display: none;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot input[type=radio]:checked + .timeBox {
    background-color: #ff5a01;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot .timeBox {
    border: 2px solid #ff5a01;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 8px 5px;
    color: white;
    transition: all 0.5s ease;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot .timeBox p {
    font-size: 14px;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot .timeBox:hover {
    background-color: #ff5a01;
  }
  .allplans .planContainer .planDetails > .planLeft .allSlotsContainer .currentSlots .currentSlot .timeSlots .timeSlot .timeBox ion-icon {
    color: white;
    font-size: 30px;
    transition: all 0.5s ease;
  }
  .allplans .planContainer .planDetails > .planRight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
  }
  .allplans .planContainer .planDetails > .planRight img {
    height: auto;
    width: 100%;
  }
  .allplans .planContainer .serviceBgFont {
    display: none;
  }
  .allplans .planContainer:nth-child(2n) .planDetails {
    flex-direction: column-reverse;
  }
  .allplans .planContainer:nth-child(2n) .planDetails > .planLeft {
    align-items: center;
  }
  .allplans .planContainer:nth-child(2n) .planDetails > .planRight {
    justify-content: center;
  }
}
.faqSection {
  background-color: #222222;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
  padding-bottom: 100px;
  position: relative;
}
.faqSection .title {
  font-size: 50px;
}
.faqSection .questionsContainer {
  margin-top: 100px;
  width: 65%;
}
.faqSection .questionsContainer details {
  border-bottom: 1px solid rgb(104, 104, 104);
}
.faqSection .questionsContainer details summary {
  width: 100%;
  padding: 10px;
  padding-bottom: 0;
  position: relative;
  cursor: pointer;
  font-weight: 300;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faqSection .questionsContainer details .que {
  width: 90%;
  position: relative;
  color: rgb(180, 180, 180);
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;
  list-style: none;
}
.faqSection .questionsContainer details .btn {
  font-size: 50px;
  color: #ff5a01;
  padding: 0;
  transform-origin: center;
  transition: all 0.5s ease;
}
.faqSection .questionsContainer details .ans {
  padding: 10px;
  padding-top: 0;
  font-size: 20px;
  width: 90%;
  text-align: left;
}
.faqSection .questionsContainer details .ans a {
  color: white;
  font-style: italic;
  padding-left: 10px;
}
.faqSection .questionsContainer details[open] summary .btn {
  transform: rotate(45deg);
}
.faqSection .questionsContainer details summary {
  outline: 0;
}
.faqSection .questionsContainer details summary::-webkit-details-marker {
  display: none;
}
.faqSection .faqBgFont {
  position: absolute;
  top: 130px;
  transform: translateX(-50%);
  left: 50%;
}
.faqSection .faqBgFont img {
  width: 80%;
}

@media screen and (max-width: 768px) {
  .faqSection {
    background-color: #222222;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    padding-bottom: 30px;
    padding-top: 30px;
    position: relative;
  }
  .faqSection .title {
    font-size: 25px;
    text-align: center;
    position: relative;
  }
  .faqSection .title::before {
    content: "";
    width: 48px;
    height: 5px;
    top: -12px;
    left: 50%;
    transform: skew(-40deg) translateX(-50%);
    position: absolute;
    background-color: #ff5a01;
  }
  .faqSection .questionsContainer {
    margin-top: 60px;
    width: 100%;
  }
  .faqSection .questionsContainer details {
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(104, 104, 104);
  }
  .faqSection .questionsContainer details summary {
    width: 100%;
    padding: 10px;
    padding-bottom: 0;
    position: relative;
    cursor: pointer;
    font-weight: 300;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .faqSection .questionsContainer details .que {
    width: 90%;
    position: relative;
    color: rgb(180, 180, 180);
    cursor: pointer;
    font-size: 20px;
    font-weight: 300;
    list-style: none;
  }
  .faqSection .questionsContainer details .btn {
    font-size: 50px;
    color: #ff5a01;
    padding: 0;
    transform-origin: center;
    transition: all 0.5s ease;
  }
  .faqSection .questionsContainer details .ans {
    padding: 10px;
    padding-top: 5px;
    font-size: 20px;
    width: 90%;
    text-align: left;
  }
  .faqSection .questionsContainer details[open] summary .btn {
    transform: rotate(45deg);
  }
  .faqSection .questionsContainer details summary {
    outline: 0;
  }
  .faqSection .questionsContainer details summary::-webkit-details-marker {
    display: none;
  }
  .faqSection .faqBgFont {
    position: absolute;
    top: 75px;
    transform: translateX(-50%);
    left: 50%;
  }
  .faqSection .faqBgFont img {
    width: auto;
    height: 50px;
  }
}
.contactForm {
  background-color: #222222;
  color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  padding: 50px 100px;
}
.contactForm .left {
  width: 50%;
}
.contactForm .left .title {
  font-size: 50px;
  width: 70%;
  margin-bottom: 20px;
  font-weight: 500;
}
.contactForm .left .desc {
  font-weight: 0;
  width: 80%;
  margin-bottom: 70px;
  font-weight: 300;
}
.contactForm .left .detail {
  margin-top: 20px;
  font-weight: 300;
  font-size: 20px;
}
.contactForm .right {
  width: 50%;
}
.contactForm .right form {
  display: flex;
  flex-direction: column;
}
.contactForm .right form .formField {
  display: flex;
  margin-bottom: 30px;
  gap: 10px;
}
.contactForm .right form .formField .subformField {
  width: 50%;
}
.contactForm .right form .formField .subformField .labelSpan {
  font-size: 14px;
}
.contactForm .right form input,
.contactForm .right form textarea {
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  outline: none;
  overflow: hidden;
  background-color: #2b2c33;
  overflow: hidden !important;
  color: white;
}
.contactForm .right form input:-webkit-autofill,
.contactForm .right form input:-webkit-autofill:hover,
.contactForm .right form input:-webkit-autofill:focus,
.contactForm .right form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #2b2c33 inset !important;
}
.contactForm .right form input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.contactForm .right form textarea {
  resize: vertical;
  overflow: scroll;
  height: 100px;
  min-height: 100px;
  max-height: 200px;
}
.contactForm .right form .mainLabel {
  margin-bottom: 10px;
}
.contactForm .right form .submit {
  align-self: flex-start;
  margin-left: 10px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5a01;
  color: #ffffff;
  border: none;
  padding: 13px 40px;
  font-size: 16px;
  cursor: pointer;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.contactForm .right form .submit ion-icon {
  font-size: 20px;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .contactForm {
    background-color: #222222;
    color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .contactForm .left {
    width: 100%;
    padding: 20px 0;
    padding-bottom: 50px;
  }
  .contactForm .left .title {
    font-size: 25px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .contactForm .left .desc {
    font-weight: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .contactForm .left .detail {
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
  }
  .contactForm .right {
    width: 100%;
  }
  .contactForm .right form {
    display: flex;
    flex-direction: column;
  }
  .contactForm .right form .formField {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    flex-direction: column;
  }
  .contactForm .right form .formField .subformField {
    width: 100%;
    margin-bottom: 10px;
  }
  .contactForm .right form .formField .subformField .labelSpan {
    font-size: 14px;
  }
  .contactForm .right form input,
.contactForm .right form textarea {
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
    outline: none;
    overflow: hidden;
    background-color: #2b2c33;
    overflow: hidden !important;
    color: white;
  }
  .contactForm .right form input:-webkit-autofill,
.contactForm .right form input:-webkit-autofill:hover,
.contactForm .right form input:-webkit-autofill:focus,
.contactForm .right form input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #2b2c33 inset !important;
  }
  .contactForm .right form input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }
  .contactForm .right form textarea {
    resize: vertical;
    overflow: scroll;
    height: 100px;
    min-height: 100px;
    max-height: 200px;
  }
  .contactForm .right form .mainLabel {
    margin-bottom: 10px;
  }
  .contactForm .right form .submit {
    align-self: center;
    margin-left: 10px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff5a01;
    color: #ffffff;
    border: none;
    padding: 13px 40px;
    font-size: 16px;
    cursor: pointer;
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  .contactForm .right form .submit ion-icon {
    font-size: 20px;
    margin-left: 10px;
  }
}
.referralPage {
  position: relative;
}
.referralPage .referralDetails {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #222222;
  align-items: center;
  padding: 50px 100px;
  color: white;
}
.referralPage .referralDetails .title {
  font-size: 40px;
}
.referralPage .referralDetails .desc {
  font-size: 20px;
  width: 60%;
  text-align: center;
  margin-top: 20px;
}
.referralPage .referralDetails .desc span {
  color: #ff5a01;
  text-decoration: underline;
  cursor: pointer;
}
.referralPage .referralDetails .details {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.referralPage .referralDetails .details .detailBox {
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.referralPage .referralDetails .details .detailBox img {
  height: 120px;
  width: 120px;
}
.referralPage .referralDetails .details .detailBox .detailDesc {
  font-size: 18px;
  width: 70%;
  text-align: center;
}
.referralPage .referralDetails form {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 30px;
}
.referralPage .referralDetails form label {
  font-size: 20px;
  margin-bottom: 20px;
}
.referralPage .referralDetails form .formField {
  border: 2px solid white;
  border-radius: 5px;
  display: flex;
  width: 100%;
  overflow: hidden;
}
.referralPage .referralDetails form .formField input {
  width: 90%;
  border: none;
  background-color: #222222;
  padding: 20px 10px;
  font-size: 20px;
  color: white;
  outline: none;
}
.referralPage .referralDetails form .formField input::-moz-placeholder {
  color: gray;
}
.referralPage .referralDetails form .formField input:-ms-input-placeholder {
  color: gray;
}
.referralPage .referralDetails form .formField input::placeholder {
  color: gray;
}
.referralPage .referralDetails form .formField input:-webkit-autofill,
.referralPage .referralDetails form .formField input:-webkit-autofill:hover,
.referralPage .referralDetails form .formField input:-webkit-autofill:focus,
.referralPage .referralDetails form .formField input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px #222222 inset !important;
}
.referralPage .referralDetails form .formField input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.referralPage .referralDetails form .formField button {
  width: 10%;
  border: none;
  font-size: 20px;
  background-color: #ff5a01;
  color: white;
  cursor: pointer;
  outline: none;
}
.referralPage .referralStatus {
  background-color: black;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 50px 100px;
  display: flex;
  color: white;
}
.referralPage .referralStatus .left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.referralPage .referralStatus .left form {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.referralPage .referralStatus .left form label {
  font-size: 20px;
  margin-bottom: 20px;
}
.referralPage .referralStatus .left form .formField {
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  width: 100%;
  overflow: hidden;
}
.referralPage .referralStatus .left form .formField input {
  width: 80%;
  border: none;
  background-color: rgb(12, 12, 12);
  padding: 16px 10px;
  font-size: 18px;
  color: white;
  outline: none;
}
.referralPage .referralStatus .left form .formField input::-moz-placeholder {
  color: gray;
}
.referralPage .referralStatus .left form .formField input:-ms-input-placeholder {
  color: gray;
}
.referralPage .referralStatus .left form .formField input::placeholder {
  color: gray;
}
.referralPage .referralStatus .left form .formField input:-webkit-autofill,
.referralPage .referralStatus .left form .formField input:-webkit-autofill:hover,
.referralPage .referralStatus .left form .formField input:-webkit-autofill:focus,
.referralPage .referralStatus .left form .formField input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px rgb(12, 12, 12) inset !important;
}
.referralPage .referralStatus .left form .formField input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.referralPage .referralStatus .left form .formField button {
  width: 20%;
  border: none;
  background-color: #ff5a01;
  color: white;
  cursor: pointer;
  font-size: 16px;
  outline: none;
}
.referralPage .referralStatus .left .shortLinks {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}
.referralPage .referralStatus .left .shortLinks a ion-icon {
  color: #ff5a01;
  font-size: 30px;
}
.referralPage .referralStatus .left .shortLinks a.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}
.referralPage .referralStatus .right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
}
.referralPage .referralStatus .right .item {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.referralPage .referralStatus .right .item .itemValue {
  font-size: 60px;
  font-weight: 700;
}
.referralPage .referralStatus .right .item .itemTitle {
  text-align: center;
  font-size: 16px;
}
.referralPage .popUpLayer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.referralPage .popUpLayer .popUpBox {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 80vh;
}
.referralPage .popUpLayer .popUpBox ion-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 25px;
  cursor: pointer;
}
.referralPage .popUpLayer .popUpBox .title {
  font-size: 30px;
  margin-bottom: 20px;
}
.referralPage .popUpLayer .popUpBox .conditions {
  overflow: auto;
  padding: 10px;
  height: 90%;
}
.referralPage .popUpLayer .popUpBox .conditions h2 {
  font-size: 22px;
}
.referralPage .popUpLayer .popUpBox .conditions .subdetail {
  margin-left: 50px;
}
.referralPage .popUpLayer .popUpBox .conditions p,
.referralPage .popUpLayer .popUpBox .conditions li {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .referralPage .referralDetails {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #222222;
    align-items: center;
    color: white;
    padding: 10px;
  }
  .referralPage .referralDetails .title {
    font-size: 25px;
    text-align: center;
    font-weight: 500;
  }
  .referralPage .referralDetails .desc {
    font-size: 20px;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .referralPage .referralDetails .desc span {
    color: #ff5a01;
    text-decoration: underline;
    cursor: pointer;
  }
  .referralPage .referralDetails .details {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 20px 0;
  }
  .referralPage .referralDetails .details .detailBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .referralPage .referralDetails .details .detailBox img {
    height: 120px;
    width: 120px;
  }
  .referralPage .referralDetails .details .detailBox .detailDesc {
    font-size: 18px;
    width: 80%;
    text-align: center;
  }
  .referralPage .referralDetails form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .referralPage .referralDetails form label {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
  }
  .referralPage .referralDetails form .formField {
    border: 2px solid white;
    border-radius: 5px;
    display: flex;
    width: 100%;
    overflow: hidden;
  }
  .referralPage .referralDetails form .formField input {
    width: 80%;
    border: none;
    background-color: rgb(12, 12, 12);
    padding: 20px 10px;
    font-size: 20px;
    color: white;
    outline: none;
  }
  .referralPage .referralDetails form .formField input::-moz-placeholder {
    color: gray;
  }
  .referralPage .referralDetails form .formField input:-ms-input-placeholder {
    color: gray;
  }
  .referralPage .referralDetails form .formField input::placeholder {
    color: gray;
  }
  .referralPage .referralDetails form .formField button {
    width: 20%;
    border: none;
    font-size: 20px;
    background-color: #ff5a01;
    color: white;
    cursor: pointer;
    outline: none;
  }
  .referralPage .referralStatus {
    background: linear-gradient(0deg, rgb(0, 0, 0) 85%, rgb(34, 34, 34) 100%);
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px 10px;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    color: white;
  }
  .referralPage .referralStatus .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .referralPage .referralStatus .left form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .referralPage .referralStatus .left form label {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  }
  .referralPage .referralStatus .left form .formField {
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    width: 100%;
    overflow: hidden;
  }
  .referralPage .referralStatus .left form .formField input {
    width: 80%;
    border: none;
    background-color: rgb(12, 12, 12);
    padding: 20px 10px;
    font-size: 16px;
    color: white;
    outline: none;
  }
  .referralPage .referralStatus .left form .formField input::-moz-placeholder {
    color: gray;
  }
  .referralPage .referralStatus .left form .formField input:-ms-input-placeholder {
    color: gray;
  }
  .referralPage .referralStatus .left form .formField input::placeholder {
    color: gray;
  }
  .referralPage .referralStatus .left form .formField button {
    width: 20%;
    border: none;
    background-color: #ff5a01;
    color: white;
    cursor: pointer;
    font-size: 16px;
    outline: none;
  }
  .referralPage .referralStatus .left .shortLinks {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
  .referralPage .referralStatus .left .shortLinks ion-icon {
    color: #ff5a01;
    font-size: 30px;
  }
  .referralPage .referralStatus .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  .referralPage .referralStatus .right .item {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .referralPage .referralStatus .right .item .itemValue {
    font-size: 60px;
    font-weight: 700;
  }
  .referralPage .referralStatus .right .item .itemTitle {
    text-align: center;
    font-size: 20px;
  }
  .referralPage .popUpLayer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .referralPage .popUpLayer .popUpBox {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 80vw;
    height: 80vh;
  }
  .referralPage .popUpLayer .popUpBox ion-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
  }
  .referralPage .popUpLayer .popUpBox .title {
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .referralPage .popUpLayer .popUpBox .conditions {
    overflow: auto;
    padding: 5px;
    height: 90%;
  }
  .referralPage .popUpLayer .popUpBox .conditions .subdetail {
    margin-left: 30px;
  }
  .referralPage .popUpLayer .popUpBox .conditions h2 {
    font-size: 20px;
  }
  .referralPage .popUpLayer .popUpBox .conditions p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
.suggestPlan {
  background-color: #222222;
  position: relative;
}
.suggestPlan .popUpLayer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.suggestPlan .popUpLayer .popUpBox {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 500px;
}
.suggestPlan .popUpLayer .popUpBox ion-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 25px;
  cursor: pointer;
}
.suggestPlan .popUpLayer .popUpBox .title {
  font-size: 25px;
  margin-bottom: 20px;
}
.suggestPlan .popUpLayer .popUpBox p {
  color: black;
  font-weight: 500;
}
.suggestPlan .popUpLayer .popUpBox .value {
  color: #ff5a01;
  margin: 5px 0;
}
.suggestPlan .popUpLayer .popUpBox .subTitle {
  font-size: 18px;
  color: black;
  font-weight: 700;
  margin-top: 10px;
}
.suggestPlan .popUpLayer .popUpBox .slogan {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.suggestPlan .popUpLayer .popUpBox .desc {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
}
.suggestPlan .popUpLayer .popUpBox input {
  width: 400px;
  padding: 10px;
  border: 2px solid #2b2c33;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  color: black;
}
.suggestPlan .popUpLayer .popUpBox button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #ff5a01;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 16px;
  margin-top: 20px;
}
.suggestPlan .quizCompleteSlogan {
  color: white;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
  font-size: 30px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .suggestPlan {
    background-color: #222222;
    position: relative;
  }
  .suggestPlan .popUpLayer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .suggestPlan .popUpLayer .popUpBox {
    background-color: white;
    padding: 20px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 90vw;
  }
  .suggestPlan .popUpLayer .popUpBox ion-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
  }
  .suggestPlan .popUpLayer .popUpBox .title {
    font-size: 25px;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 10px;
  }
  .suggestPlan .popUpLayer .popUpBox p {
    color: black;
    font-weight: 500;
  }
  .suggestPlan .popUpLayer .popUpBox .value {
    color: #ff5a01;
    margin: 5px 0;
  }
  .suggestPlan .popUpLayer .popUpBox .subTitle {
    font-size: 18px;
    color: black;
    font-weight: 700;
  }
  .suggestPlan .popUpLayer .popUpBox .slogan {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .suggestPlan .popUpLayer .popUpBox .desc {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .suggestPlan .popUpLayer .popUpBox input {
    width: 80vw;
    padding: 10px;
    border: 2px solid #2b2c33;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 16px;
    color: black;
  }
  .suggestPlan .popUpLayer .popUpBox button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #ff5a01;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 16px;
    margin-top: 20px;
  }
  .suggestPlan .quizCompleteSlogan {
    color: white;
    text-align: center;
    padding-top: 20px;
    margin: 0;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 500;
  }
}
.userDetail {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: calc(100vh - 130px);
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
}
.userDetail .container {
  display: flex;
  width: 100%;
}
.userDetail .container .userDetailLeft {
  width: 50%;
  padding-left: 150px;
}
.userDetail .container .userDetailLeft .userDetailTitle {
  font-size: 30px;
}
.userDetail .container .userDetailLeft form {
  width: 80%;
  margin-top: 30px;
}
.userDetail .container .userDetailLeft form label {
  margin-left: 10px;
}
.userDetail .container .userDetailLeft form .formField {
  border: 1px solid white;
  margin-top: 8px;
  margin-bottom: 10px;
  background-color: #2b2c33;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px 15px;
  overflow: hidden;
}
.userDetail .container .userDetailLeft form .formField.promoField {
  padding: 0;
  margin-bottom: 10px;
}
.userDetail .container .userDetailLeft form .formField.promoField ion-icon {
  color: #ff5a01;
  margin: 5px 15px;
  font-size: 35px;
  margin-right: 0;
}
.userDetail .container .userDetailLeft form .formField ion-icon {
  color: #ff5a01;
  font-size: 25px;
}
.userDetail .container .userDetailLeft form .formField button {
  border-radius: 0;
}
.userDetail .container .userDetailLeft form .formField input {
  width: 95%;
  color: white;
  padding: 8px 10px;
  background-color: #2b2c33;
  border: none;
  outline: none;
  font-size: 16px;
}
.userDetail .container .userDetailLeft form .formField input:-webkit-autofill,
.userDetail .container .userDetailLeft form .formField input:-webkit-autofill:hover,
.userDetail .container .userDetailLeft form .formField input:-webkit-autofill:focus,
.userDetail .container .userDetailLeft form .formField input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #2b2c33 inset !important;
}
.userDetail .container .userDetailLeft form .formField input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.userDetail .container .userDetailLeft form .resMsg {
  margin-bottom: 10px;
}
.userDetail .container .userDetailLeft form button {
  border: none;
  background-color: #ff5a01;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
}
.userDetail .container .userDetailLeft .payBtn {
  border: none;
  background-color: #ff5a01;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  font-size: 18px;
  margin-top: 20px;
}
.userDetail .container .userDetailLeft .payBtn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.userDetail .container .userDetailRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userDetail .container .userDetailRight .userDetailCard {
  overflow: hidden;
  border-radius: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #ff5a01;
  padding: 15px;
  justify-content: flex-start;
  width: 45%;
}
.userDetail .container .userDetailRight .userDetailCard .cardTitle {
  font-size: 20px;
  font-weight: 300;
  word-wrap: break-word;
}
.userDetail .container .userDetailRight .userDetailCard .detailBox {
  display: flex;
  margin-top: 15px;
  gap: 15px;
}
.userDetail .container .userDetailRight .userDetailCard .detailBox .boxLeft {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.userDetail .container .userDetailRight .userDetailCard .detailBox .boxLeft .userImage {
  border: 2px solid white;
  overflow: hidden;
  padding: 5px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userDetail .container .userDetailRight .userDetailCard .detailBox .boxLeft .userImage ion-icon {
  font-size: 35px;
}
.userDetail .container .userDetailRight .userDetailCard .detailBox .boxRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.userDetail .container .userDetailRight .userDetailCard .detailBox .boxRight p {
  font-size: 14px;
}
.userDetail .container .userDetailRight .userDetailCard .detailBox .boxRight .useremail {
  word-wrap: break-word;
}
.userDetail .container .userDetailRight .userDetailCard .detailBox .boxRight .username {
  font-size: 20px;
}
.userDetail .container .userDetailRight .planDetailCard {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #ff5a01;
  margin-top: 30px;
  border-radius: 10px;
  width: 45%;
}
.userDetail .container .userDetailRight .planDetailCard .cardTitle {
  font-size: 20px;
  font-weight: 300;
}
.userDetail .container .userDetailRight .planDetailCard .detailBox {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 10px;
}
.userDetail .container .userDetailRight .planDetailCard .detailBox p {
  font-size: 14px;
  font-weight: 300;
}
.userDetail .container .userDetailRight .planDetailCard .detailBox .dates,
.userDetail .container .userDetailRight .planDetailCard .detailBox .timeSlot {
  font-weight: 500;
}
.userDetail .container .userDetailRight .planDetailCard .detailBox .dateValue {
  margin-bottom: 10px;
}
.userDetail .container .userDetailRight .planDetailCard .detailBox .trainerDetail {
  display: flex;
  margin: 5px 0;
  align-items: center;
  gap: 20px;
}
.userDetail .container .userDetailRight .planDetailCard .detailBox .trainerDetail .trainerDetailRight p:first-child {
  font-weight: 500;
}
.userDetail .container .userDetailRight .planDetailCard .detailBox .trainerDetail .trainerDetailLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.userDetail .container .userDetailRight .planDetailCard .detailBox .trainerDetail .trainerDetailLeft img {
  height: 80px;
  border-radius: 10px;
}
.userDetail .container .userDetailRight .planDetailCard .paymentDetailBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 10px 0;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}
.userDetail .container .userDetailRight .planDetailCard .paymentDetailBox .boxRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.userDetail .container .userDetailRight .planDetailCard .paymentDetailBox .boxRight .totalAmount {
  font-weight: 500;
  font-size: 18px;
}
.userDetail .container .userDetailRight .planDetailCard .paymentDetailBox .boxRight .sessionAmount {
  text-align: right;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .userDetail {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    width: 100%;
    background-color: #222222;
    color: white;
    display: flex;
    padding: 20px 0;
    align-items: center;
  }
  .userDetail .container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    padding: 10px;
  }
  .userDetail .container .userDetailLeft {
    width: 100%;
    padding-left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
  }
  .userDetail .container .userDetailLeft .userDetailTitle {
    font-size: 30px;
    text-align: center;
  }
  .userDetail .container .userDetailLeft form {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 20px;
  }
  .userDetail .container .userDetailLeft form label {
    margin-left: 10px;
  }
  .userDetail .container .userDetailLeft form .formField {
    border: 1px solid white;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #2b2c33;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 5px 15px;
  }
  .userDetail .container .userDetailLeft form .formField.promoField {
    padding: 0;
  }
  .userDetail .container .userDetailLeft form .formField.promoField ion-icon {
    font-size: 35px;
  }
  .userDetail .container .userDetailLeft form .formField ion-icon {
    color: #ff5a01;
    font-size: 25px;
  }
  .userDetail .container .userDetailLeft form .formField input {
    width: 95%;
    color: white;
    padding: 8px 10px;
    background-color: #2b2c33;
    border: none;
    outline: none;
    font-size: 16px;
  }
  .userDetail .container .userDetailLeft form button {
    align-self: center;
    border: none;
    background-color: #ff5a01;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 18px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
  }
  .userDetail .container .userDetailLeft form button img {
    height: 22px;
    margin-left: 7px;
  }
  .userDetail .container .userDetailRight {
    padding-bottom: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .userDetail .container .userDetailRight .userDetailCard {
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #ff5a01;
    padding: 15px;
    justify-content: flex-start;
    width: 100%;
    display: none;
  }
  .userDetail .container .userDetailRight .userDetailCard .cardTitle {
    font-size: 20px;
    font-weight: 300;
  }
  .userDetail .container .userDetailRight .userDetailCard .detailBox {
    display: flex;
    margin-top: 15px;
    gap: 15px;
  }
  .userDetail .container .userDetailRight .userDetailCard .detailBox .boxLeft {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .userDetail .container .userDetailRight .userDetailCard .detailBox .boxLeft .userImage {
    border: 2px solid white;
    overflow: hidden;
    padding: 5px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .userDetail .container .userDetailRight .userDetailCard .detailBox .boxLeft .userImage ion-icon {
    font-size: 35px;
  }
  .userDetail .container .userDetailRight .userDetailCard .detailBox .boxRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }
  .userDetail .container .userDetailRight .userDetailCard .detailBox .boxRight p {
    font-size: 14px;
  }
  .userDetail .container .userDetailRight .userDetailCard .detailBox .boxRight .username {
    font-size: 20px;
  }
  .userDetail .container .userDetailRight .planDetailCard {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #ff5a01;
    margin-top: 0;
    border-radius: 10px;
    width: 100%;
  }
  .userDetail .container .userDetailRight .planDetailCard .cardTitle {
    font-size: 25px;
    font-weight: 300;
  }
  .userDetail .container .userDetailRight .planDetailCard .detailBox {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 10px;
  }
  .userDetail .container .userDetailRight .planDetailCard .detailBox p {
    font-size: 14px;
    font-weight: 300;
  }
  .userDetail .container .userDetailRight .planDetailCard .detailBox .dates,
.userDetail .container .userDetailRight .planDetailCard .detailBox .timeSlot {
    font-weight: 500;
  }
  .userDetail .container .userDetailRight .planDetailCard .detailBox .dateValue {
    margin-bottom: 10px;
  }
  .userDetail .container .userDetailRight .planDetailCard .detailBox .trainerDetail {
    display: flex;
    margin: 5px 0;
    align-items: center;
    gap: 20px;
  }
  .userDetail .container .userDetailRight .planDetailCard .detailBox .trainerDetail .trainerDetailRight p:first-child {
    font-weight: 500;
  }
  .userDetail .container .userDetailRight .planDetailCard .paymentDetailBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px 0;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
  }
  .userDetail .container .userDetailRight .planDetailCard .paymentDetailBox .boxRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .userDetail .container .userDetailRight .planDetailCard .paymentDetailBox .boxRight .totalAmount {
    font-weight: 500;
    font-size: 18px;
  }
  .userDetail .container .userDetailRight .planDetailCard .paymentDetailBox .boxRight .sessionAmount {
    text-align: right;
    font-size: 12px;
  }
}
.scrollTop {
  position: fixed;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  bottom: 20px;
  right: 20px;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  z-index: 1000;
  cursor: pointer;
  -webkit-animation: fadeIn 0.3s;
          animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  display: none;
  color: white;
  border-radius: 5px;
  background: #ff5a01;
}

.scrollTop:hover {
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.adminlogin {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  /* Firefox */
}
.adminlogin input::-webkit-outer-spin-button,
.adminlogin input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.adminlogin input[type=number] {
  -moz-appearance: textfield;
}
.adminlogin .left {
  height: 100%;
  width: 50%;
  background: url("../Assets/loginBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.adminlogin .left .layer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.adminlogin .left .adminLogo {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 250px;
  pointer-events: none;
}
.adminlogin .right {
  background-color: #222222;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.adminlogin .right a > img {
  height: 100px;
  margin-top: 80px;
}
.adminlogin .right .loginBox {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2b2c33;
  padding: 20px 20px;
  width: 380px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  min-height: 200px;
}
.adminlogin .right .loginBox .tokenError {
  color: white;
  margin: auto;
  text-align: center;
  font-weight: 300;
  font-size: 25px;
}
.adminlogin .right .loginBox .title {
  font-size: 25px;
  font-weight: 300;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}
.adminlogin .right .loginBox .instruction {
  text-align: center;
  margin-bottom: 10px;
  color: white;
}
.adminlogin .right .loginBox form {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.adminlogin .right .loginBox form input {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  background: none;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  outline: none;
}
.adminlogin .right .loginBox form input::-moz-placeholder {
  color: white;
}
.adminlogin .right .loginBox form input:-ms-input-placeholder {
  color: white;
}
.adminlogin .right .loginBox form input::placeholder {
  color: white;
}
.adminlogin .right .loginBox form .resMsg {
  margin-top: 0;
}
.adminlogin .right .loginBox form button {
  cursor: pointer;
  padding: 10px;
  width: 100%;
  font-weight: 500;
  background-color: #ff5a01;
  border: none;
  color: white;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
}
.adminlogin .right .loginBox .subLink {
  color: #ff5a01;
  margin-top: 10px;
  cursor: pointer;
}

.adminTop {
  display: flex;
  justify-content: flex-end;
  background: #2b2c33;
  align-items: center;
  height: 60px;
}
.adminTop .profile {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}
.adminTop .profile .userImage {
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 50%;
  padding: 5px;
}
.adminTop .profile .userImage ion-icon {
  font-size: 25px;
}
.adminTop .profile .title {
  font-weight: 300;
  font-size: 20px;
}

.adminMainContent {
  display: flex;
  height: calc(100vh - 60px);
  width: 100vw;
  overflow: hidden;
}
.adminMainContent .adminSide {
  width: 200px;
  background: #2b2c33;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  color: white;
}
.adminMainContent .adminSide .logo {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.adminMainContent .adminSide .logo img {
  height: 60px;
}
.adminMainContent .adminSide .menus {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.adminMainContent .adminSide .menus .subMenu {
  list-style: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 10px;
  margin-bottom: 10px;
}
.adminMainContent .adminSide .menus .subMenu .link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: white;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s linear;
}
.adminMainContent .adminSide .menus .subMenu .link:hover {
  opacity: 1;
}
.adminMainContent .adminSide .menus .subMenu .link.menuActive {
  opacity: 1;
}
.adminMainContent .adminSide .menus .subMenu .link ion-icon {
  margin-right: 5px;
  font-size: 20px;
}
.adminMainContent .adminSide .menus .subMenu .subLink {
  text-decoration: none;
  color: white;
  margin-left: 25px;
  font-size: 16px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.adminMainContent .adminSide .menus .subMenu .subLink:hover {
  opacity: 1;
}
.adminMainContent .adminSide .menus .subMenu .subLink.active {
  opacity: 1;
}

.adminMainContent {
  /* Firefox */
}
.adminMainContent .right {
  background-color: #222222;
  width: 100%;
  height: 100%;
  padding-left: 250px;
  padding-top: 50px;
  color: white;
}
.adminMainContent button {
  outline: none;
}
.adminMainContent input:-webkit-autofill,
.adminMainContent input:-webkit-autofill:hover,
.adminMainContent input:-webkit-autofill:focus,
.adminMainContent input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px #222222 inset !important;
}
.adminMainContent input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.adminMainContent input::-webkit-outer-spin-button,
.adminMainContent input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.adminMainContent input[type=number] {
  -moz-appearance: textfield;
}
.adminMainContent .adminSales h2 {
  font-weight: 500;
}
.adminMainContent .adminSales form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 800px;
  margin-left: 20px;
}
.adminMainContent .adminSales form .fieldTitle {
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 8px;
}
.adminMainContent .adminSales form .formField {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.adminMainContent .adminSales form .formField .dateBox {
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.adminMainContent .adminSales form .formField .dateBox .subFormField {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.adminMainContent .adminSales form .formField .dateBox .subFormField input[type=text],
.adminMainContent .adminSales form .formField .dateBox .subFormField input[type=number] {
  width: 100%;
}
.adminMainContent .adminSales form .formField .dateBox .subFormField input[type=text]#discount,
.adminMainContent .adminSales form .formField .dateBox .subFormField input[type=number]#discount {
  width: 70%;
}
.adminMainContent .adminSales form .formField .dateBox .subFormField label {
  font-size: 14px;
  margin-bottom: 5px;
}
.adminMainContent .adminSales form .formField textarea {
  width: 100%;
  height: 100px;
  resize: none;
  background: none;
  border: 1px solid white;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: 300;
}
.adminMainContent .adminSales form .formField textarea::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSales form .formField textarea:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSales form .formField textarea::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSales form .formField input[type=text],
.adminMainContent .adminSales form .formField input[type=number] {
  background: none;
  outline: none;
  padding: 13px 10px;
  border: 1px solid white;
  width: 40%;
  border-radius: 5px;
  color: white;
}
.adminMainContent .adminSales form .formField input[type=text]::-moz-placeholder, .adminMainContent .adminSales form .formField input[type=number]::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSales form .formField input[type=text]:-ms-input-placeholder, .adminMainContent .adminSales form .formField input[type=number]:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSales form .formField input[type=text]::placeholder,
.adminMainContent .adminSales form .formField input[type=number]::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSales form .formField input[type=date] {
  background: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
}
.adminMainContent .adminSales form .formField input[type=date]::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSales form .formField input[type=date]:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSales form .formField input[type=date]::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSales form .formField ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.adminMainContent .adminSales form .formField label {
  font-size: 16px;
  margin-bottom: 8px;
}
.adminMainContent .adminSales form .formField .fieldTitle {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}
.adminMainContent .adminSales form button {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  outline: none;
  margin-top: 10px;
}
.adminMainContent .adminHistory {
  padding-right: 20px;
  padding-bottom: 50px;
}
.adminMainContent .adminHistory h2 {
  font-weight: 500;
}
.adminMainContent .adminHistory .filterContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.adminMainContent .adminHistory .filterContainer .filter {
  display: flex;
  align-items: center;
  gap: 10px;
}
.adminMainContent .adminHistory .filterContainer .filter .filterText {
  font-size: 18px;
  margin-right: 5px;
}
.adminMainContent .adminHistory .filterContainer .filter .dropDown {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 200px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.adminMainContent .adminHistory .filterContainer .filter .dropDown .dropDown-Btn {
  padding: 8px 13px;
  background: gray;
  border-radius: 5px;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 300;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.adminMainContent .adminHistory .filterContainer .filter .dropDown .dropDown-Btn ion-icon {
  margin-left: 5px;
}
.adminMainContent .adminHistory .filterContainer .filter .dropDown .dropDown-content {
  position: absolute;
  top: 120%;
  left: 0;
  padding: 3px 0;
  background: gray;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 300;
  border-radius: 5px;
  color: white;
  z-index: 2;
  width: 100%;
  z-index: 4;
}
.adminMainContent .adminHistory .filterContainer .filter .dropDown .dropDown-content .dropDown-item {
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s;
}
.adminMainContent .adminHistory .filterContainer .filter .dropDown .dropDown-content .dropDown-item:hover {
  background-color: #2b2c33;
}
.adminMainContent .adminHistory .filterContainer .exportBtn {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.adminMainContent .adminHistory .tableDiv {
  margin-top: 20px;
  height: 85%;
  position: relative;
  overflow: auto;
}
.adminMainContent .adminHistory .tableDiv table {
  border-collapse: collapse;
  border-spacing: 0 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 100%;
  border: none;
  overflow-x: auto;
}
.adminMainContent .adminHistory .tableDiv .noData {
  text-align: center;
  width: 100%;
  font-size: 20px;
  padding: 20px 0;
}
.adminMainContent .adminHistory .tableDiv th,
.adminMainContent .adminHistory .tableDiv td {
  text-align: left;
  padding: 8px 8px;
  text-align: center;
}
.adminMainContent .adminHistory .tableDiv th {
  background: #2b2c33;
  font-weight: 500;
  font-size: 18px !important;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}
.adminMainContent .adminHistory .tableDiv .bannerText {
  text-align: left;
  max-width: 300px;
  word-wrap: break-word;
}
.adminMainContent .adminHistory .tableDiv .status {
  border: 2px solid white;
}
.adminMainContent .adminHistory .tableDiv .status.running {
  background-color: #3ccf4e;
}
.adminMainContent .adminHistory .tableDiv .status.completed {
  background-color: #e64848;
}
.adminMainContent .adminHistory .tableDiv .edit button,
.adminMainContent .adminHistory .tableDiv .end button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: none;
  margin: auto;
  border: none;
  cursor: pointer;
}
.adminMainContent .adminHistory .tableDiv .edit button img,
.adminMainContent .adminHistory .tableDiv .end button img {
  height: 100%;
  width: 100%;
  -webkit-user-drag: none;
}
.adminMainContent .adminHistory .tableDiv .edit button:disabled,
.adminMainContent .adminHistory .tableDiv .end button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.adminMainContent .adminSettings h2 {
  font-weight: 500;
}
.adminMainContent .adminSettings form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 30px;
}
.adminMainContent .adminSettings form .fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.adminMainContent .adminSettings form .fields .formField {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 45%;
}
.adminMainContent .adminSettings form .fields .formField input {
  background: none;
  outline: none;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 100%;
}
.adminMainContent .adminSettings form .fields .formField input::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSettings form .fields .formField input:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSettings form .fields .formField input::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSettings form .fields .formField .fieldTitle {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}
.adminMainContent .adminSettings form button {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
}
.adminMainContent .adminSettings .form2 {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 50px;
}
.adminMainContent .adminSettings .form2 .fieldTitle {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}
.adminMainContent .adminSettings .form2 .fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.adminMainContent .adminSettings .form2 .fields .formField {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 30%;
}
.adminMainContent .adminSettings .form2 .fields .formField input {
  background: none;
  outline: none;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 100%;
}
.adminMainContent .adminSettings .form2 .fields .formField input::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSettings .form2 .fields .formField input:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSettings .form2 .fields .formField input::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSettings .form2 button {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.adminMainContent .adminOurDetails h2 {
  font-weight: 500;
}
.adminMainContent .adminOurDetails form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 30px;
}
.adminMainContent .adminOurDetails form .fields {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.adminMainContent .adminOurDetails form .fields .formField {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 45%;
}
.adminMainContent .adminOurDetails form .fields .formField input {
  background: none;
  outline: none;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 100%;
}
.adminMainContent .adminOurDetails form .fields .formField input::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminOurDetails form .fields .formField input:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminOurDetails form .fields .formField input::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminOurDetails form .fields .formField .fieldTitle {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}
.adminMainContent .adminOurDetails form button {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
}
.adminMainContent .adminBannerUpdate h2 {
  font-weight: 500;
}
.adminMainContent .adminBannerUpdate form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 800px;
  margin-left: 20px;
}
.adminMainContent .adminBannerUpdate form .fieldTitle {
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 8px;
}
.adminMainContent .adminBannerUpdate form .formField {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.adminMainContent .adminBannerUpdate form .formField .dateBox {
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.adminMainContent .adminBannerUpdate form .formField .dateBox .subFormField {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.adminMainContent .adminBannerUpdate form .formField .dateBox .subFormField input[type=text],
.adminMainContent .adminBannerUpdate form .formField .dateBox .subFormField input[type=number] {
  width: 100%;
}
.adminMainContent .adminBannerUpdate form .formField .dateBox .subFormField input[type=text]#discount,
.adminMainContent .adminBannerUpdate form .formField .dateBox .subFormField input[type=number]#discount {
  width: 70%;
}
.adminMainContent .adminBannerUpdate form .formField .dateBox .subFormField label {
  font-size: 14px;
  margin-bottom: 5px;
}
.adminMainContent .adminBannerUpdate form .formField textarea {
  width: 100%;
  height: 100px;
  resize: none;
  background: none;
  border: 1px solid white;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: 300;
}
.adminMainContent .adminBannerUpdate form .formField textarea::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminBannerUpdate form .formField textarea:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminBannerUpdate form .formField textarea::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminBannerUpdate form .formField input[type=text],
.adminMainContent .adminBannerUpdate form .formField input[type=number] {
  background: none;
  outline: none;
  padding: 13px 10px;
  border: 1px solid white;
  width: 40%;
  border-radius: 5px;
  color: white;
}
.adminMainContent .adminBannerUpdate form .formField input[type=text]::-moz-placeholder, .adminMainContent .adminBannerUpdate form .formField input[type=number]::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminBannerUpdate form .formField input[type=text]:-ms-input-placeholder, .adminMainContent .adminBannerUpdate form .formField input[type=number]:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminBannerUpdate form .formField input[type=text]::placeholder,
.adminMainContent .adminBannerUpdate form .formField input[type=number]::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminBannerUpdate form .formField input[type=date] {
  background: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
}
.adminMainContent .adminBannerUpdate form .formField input[type=date]::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminBannerUpdate form .formField input[type=date]:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminBannerUpdate form .formField input[type=date]::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminBannerUpdate form .formField ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.adminMainContent .adminBannerUpdate form .formField label {
  font-size: 16px;
  margin-bottom: 8px;
}
.adminMainContent .adminBannerUpdate form .formField .fieldTitle {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}
.adminMainContent .adminBannerUpdate form button {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
}
.adminMainContent .adminSingleCohort {
  padding-right: 20px;
  overflow-y: auto;
  padding-bottom: 50px;
  overflow-x: hidden;
}
.adminMainContent .adminSingleCohort .topCohortBar {
  display: flex;
  justify-content: space-between;
}
.adminMainContent .adminSingleCohort .topCohortBar h2 {
  font-weight: 500;
}
.adminMainContent .adminSingleCohort .topCohortBar .deleteCohort {
  border: none;
  background-color: #fb2c2c;
  padding: 10px;
  width: 100px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
}
.adminMainContent .adminSingleCohort form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 30px;
}
.adminMainContent .adminSingleCohort form .fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.adminMainContent .adminSingleCohort form .formField {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
}
.adminMainContent .adminSingleCohort form .formField input {
  background: none;
  outline: none;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 50%;
}
.adminMainContent .adminSingleCohort form .formField input::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSingleCohort form .formField input:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSingleCohort form .formField input::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSingleCohort form .formField input::-webkit-outer-spin-button,
.adminMainContent .adminSingleCohort form .formField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.adminMainContent .adminSingleCohort form .formField textarea {
  width: 100%;
  height: 120px;
  resize: none;
  background: none;
  border: 1px solid white;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: 300;
}
.adminMainContent .adminSingleCohort form .formField textarea::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSingleCohort form .formField textarea:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSingleCohort form .formField textarea::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminSingleCohort form .formField .fieldTitle {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}
.adminMainContent .adminSingleCohort form button {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
}
.adminMainContent .adminSingleCohort .tableDiv {
  margin-top: 20px;
  position: relative;
}
.adminMainContent .adminSingleCohort .tableDiv table {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  border: none;
}
.adminMainContent .adminSingleCohort .tableDiv th,
.adminMainContent .adminSingleCohort .tableDiv td {
  text-align: left;
  padding: 5px 8px;
  text-align: center;
}
.adminMainContent .adminSingleCohort .tableDiv th {
  background: #2b2c33;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}
.adminMainContent .adminSingleCohort .tableDiv .bannerText {
  text-align: left;
}
.adminMainContent .adminSingleCohort .tableDiv .status {
  color: black;
  font-weight: 500;
}
.adminMainContent .adminSingleCohort .tableDiv .status.Available {
  background-color: #3ccf4e;
}
.adminMainContent .adminSingleCohort .tableDiv .status.Closed {
  background-color: #e64848;
}
.adminMainContent .adminSingleCohort .tableDiv .status.Notify {
  background-color: #fff89a;
}
.adminMainContent .adminSingleCohort .tableDiv .edit button,
.adminMainContent .adminSingleCohort .tableDiv .end button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin: auto;
  background: none;
  border: none;
  cursor: pointer;
}
.adminMainContent .adminSingleCohort .tableDiv .edit button img,
.adminMainContent .adminSingleCohort .tableDiv .end button img {
  height: 100%;
  width: 100%;
  -webkit-user-drag: none;
}
.adminMainContent .adminSingleCohort .bottomCohortBar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.adminMainContent .adminSingleCohort .bottomCohortBar .addSlot {
  border: none;
  background-color: #ff5a01;
  padding: 10px;
  width: 100px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
}
.adminMainContent .adminUpdateSlot {
  padding-right: 20px;
  overflow-y: auto;
  padding-bottom: 50px;
  overflow-x: hidden;
}
.adminMainContent .adminUpdateSlot h2 {
  font-weight: 500;
}
.adminMainContent .adminUpdateSlot form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 30px;
}
.adminMainContent .adminUpdateSlot form .addSlotBtn {
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right: 60px;
}
.adminMainContent .adminUpdateSlot form .fields {
  display: flex;
  gap: 100px;
  width: 100%;
}
.adminMainContent .adminUpdateSlot form .formField {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.adminMainContent .adminUpdateSlot form .formField.deleteField {
  align-self: flex-end;
  padding-bottom: 3px;
}
.adminMainContent .adminUpdateSlot form .formField .delSlot {
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}
.adminMainContent .adminUpdateSlot form .formField input {
  background: none;
  outline: none;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 50%;
}
.adminMainContent .adminUpdateSlot form .formField input::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminUpdateSlot form .formField input:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminUpdateSlot form .formField input::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminUpdateSlot form .formField input[type=date] {
  background: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 300px;
}
.adminMainContent .adminUpdateSlot form .formField input[type=date]::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminUpdateSlot form .formField input[type=date]:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminUpdateSlot form .formField input[type=date]::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminUpdateSlot form .formField .dropDown {
  width: 300px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.adminMainContent .adminUpdateSlot form .formField .dropDown .dropDown-Btn {
  padding: 10px 15px;
  background: #2b2c33;
  border-radius: 5px;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.adminMainContent .adminUpdateSlot form .formField .dropDown .dropDown-content {
  position: absolute;
  top: 120%;
  left: 0;
  padding: 10px;
  background: #2b2c33;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 300;
  border-radius: 5px;
  color: white;
  z-index: 2;
  width: 100%;
}
.adminMainContent .adminUpdateSlot form .formField .dropDown .dropDown-content .dropDown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.adminMainContent .adminUpdateSlot form .formField .dropDown .dropDown-content .dropDown-item:hover {
  background-color: #222222;
}
.adminMainContent .adminUpdateSlot form .formField ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.adminMainContent .adminUpdateSlot form .formField input::-webkit-outer-spin-button,
.adminMainContent .adminUpdateSlot form .formField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.adminMainContent .adminUpdateSlot form .fieldTitle {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}
.adminMainContent .adminUpdateSlot form .timeSlots {
  margin: 10px 0;
}
.adminMainContent .adminUpdateSlot form .timeSlots .singleTimeSlot {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}
.adminMainContent .adminUpdateSlot form .timeSlots .singleTimeSlot .formField {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
}
.adminMainContent .adminUpdateSlot form .timeSlots .singleTimeSlot .formField .fieldTitle {
  font-size: 14px;
}
.adminMainContent .adminUpdateSlot form .timeSlots .singleTimeSlot .formField input {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  padding: 8px;
}
.adminMainContent .adminUpdateSlot form .timeSlots .singleTimeSlot .formField .zoomLink {
  width: 450px;
}
.adminMainContent .adminUpdateSlot form .timeSlots .singleTimeSlot .formField .trainerName {
  width: 250px;
}
.adminMainContent .adminUpdateSlot form button {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
}
.adminMainContent .adminCreateSlot {
  padding-right: 20px;
  overflow-y: auto;
  padding-bottom: 50px;
  overflow-x: hidden;
}
.adminMainContent .adminCreateSlot h2 {
  font-weight: 500;
}
.adminMainContent .adminCreateSlot form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 30px;
}
.adminMainContent .adminCreateSlot form .addSlotBtn {
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right: 60px;
}
.adminMainContent .adminCreateSlot form .fields {
  display: flex;
  gap: 100px;
  width: 100%;
}
.adminMainContent .adminCreateSlot form .formField {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.adminMainContent .adminCreateSlot form .formField.deleteField {
  align-self: flex-end;
  padding-bottom: 3px;
}
.adminMainContent .adminCreateSlot form .formField .delSlot {
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}
.adminMainContent .adminCreateSlot form .formField input {
  background: none;
  outline: none;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 50%;
}
.adminMainContent .adminCreateSlot form .formField input::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateSlot form .formField input:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateSlot form .formField input::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateSlot form .formField input[type=date] {
  background: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 300px;
}
.adminMainContent .adminCreateSlot form .formField input[type=date]::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateSlot form .formField input[type=date]:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateSlot form .formField input[type=date]::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateSlot form .formField .dropDown {
  width: 300px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.adminMainContent .adminCreateSlot form .formField .dropDown .dropDown-Btn {
  padding: 10px 15px;
  background: #2b2c33;
  border-radius: 5px;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.adminMainContent .adminCreateSlot form .formField .dropDown .dropDown-content {
  position: absolute;
  top: 120%;
  left: 0;
  padding: 10px;
  background: #2b2c33;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 300;
  border-radius: 5px;
  color: white;
  z-index: 2;
  width: 100%;
}
.adminMainContent .adminCreateSlot form .formField .dropDown .dropDown-content .dropDown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.adminMainContent .adminCreateSlot form .formField .dropDown .dropDown-content .dropDown-item:hover {
  background-color: #222222;
}
.adminMainContent .adminCreateSlot form .formField ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.adminMainContent .adminCreateSlot form .formField input::-webkit-outer-spin-button,
.adminMainContent .adminCreateSlot form .formField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.adminMainContent .adminCreateSlot form .fieldTitle {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}
.adminMainContent .adminCreateSlot form .timeSlots {
  margin: 10px 0;
}
.adminMainContent .adminCreateSlot form .timeSlots .singleTimeSlot {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}
.adminMainContent .adminCreateSlot form .timeSlots .singleTimeSlot .formField {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
}
.adminMainContent .adminCreateSlot form .timeSlots .singleTimeSlot .formField .fieldTitle {
  font-size: 14px;
}
.adminMainContent .adminCreateSlot form .timeSlots .singleTimeSlot .formField input {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  padding: 8px;
}
.adminMainContent .adminCreateSlot form .timeSlots .singleTimeSlot .formField .zoomLink {
  width: 450px;
}
.adminMainContent .adminCreateSlot form .timeSlots .singleTimeSlot .formField .trainerName {
  width: 250px;
}
.adminMainContent .adminCreateSlot form button {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
}
.adminMainContent .adminCreateCohort {
  padding-right: 20px;
  overflow-y: auto;
  padding-bottom: 50px;
  overflow-x: hidden;
}
.adminMainContent .adminCreateCohort .topCohortBar {
  display: flex;
  justify-content: space-between;
}
.adminMainContent .adminCreateCohort .topCohortBar h2 {
  font-weight: 500;
}
.adminMainContent .adminCreateCohort .topCohortBar .deleteCohort {
  border: none;
  background-color: #fb2c2c;
  padding: 10px;
  width: 100px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
}
.adminMainContent .adminCreateCohort form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 30px;
}
.adminMainContent .adminCreateCohort form .fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.adminMainContent .adminCreateCohort form .formField {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
}
.adminMainContent .adminCreateCohort form .formField input {
  background: none;
  outline: none;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 50%;
}
.adminMainContent .adminCreateCohort form .formField input::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateCohort form .formField input:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateCohort form .formField input::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateCohort form .formField input::-webkit-outer-spin-button,
.adminMainContent .adminCreateCohort form .formField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.adminMainContent .adminCreateCohort form .formField textarea {
  width: 100%;
  height: 120px;
  resize: none;
  background: none;
  border: 1px solid white;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: 300;
}
.adminMainContent .adminCreateCohort form .formField textarea::-moz-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateCohort form .formField textarea:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateCohort form .formField textarea::placeholder {
  color: white;
  opacity: 0.7;
  font-weight: 0;
}
.adminMainContent .adminCreateCohort form .formField .fieldTitle {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}
.adminMainContent .adminCreateCohort form button {
  background-color: #ff5a01;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
}
.adminMainContent .adminCreateCohort .tableDiv {
  margin-top: 20px;
  position: relative;
}
.adminMainContent .adminCreateCohort .tableDiv table {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  border: none;
}
.adminMainContent .adminCreateCohort .tableDiv th,
.adminMainContent .adminCreateCohort .tableDiv td {
  text-align: left;
  padding: 5px 8px;
  text-align: center;
}
.adminMainContent .adminCreateCohort .tableDiv th {
  background: #2b2c33;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}
.adminMainContent .adminCreateCohort .tableDiv .bannerText {
  text-align: left;
}
.adminMainContent .adminCreateCohort .tableDiv .status {
  color: black;
  font-weight: 500;
}
.adminMainContent .adminCreateCohort .tableDiv .status.Available {
  background-color: #3ccf4e;
}
.adminMainContent .adminCreateCohort .tableDiv .status.Closed {
  background-color: #e64848;
}
.adminMainContent .adminCreateCohort .tableDiv .status.Notify {
  background-color: #fff89a;
}
.adminMainContent .adminCreateCohort .tableDiv .edit button,
.adminMainContent .adminCreateCohort .tableDiv .end button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin: auto;
  background: none;
  border: none;
  cursor: pointer;
}
.adminMainContent .adminCreateCohort .tableDiv .edit button img,
.adminMainContent .adminCreateCohort .tableDiv .end button img {
  height: 100%;
  width: 100%;
  -webkit-user-drag: none;
}
.adminMainContent .adminCreateCohort .bottomCohortBar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.adminMainContent .adminCreateCohort .bottomCohortBar .addSlot {
  border: none;
  background-color: #ff5a01;
  padding: 10px;
  width: 100px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
}

.loaderMain {
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden !important;
  height: 100vh;
  width: 100%;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff5a01;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@-webkit-keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.offerBanner {
  display: flex;
  background-color: #2b2c33;
  color: white;
  height: 60px;
  align-items: center;
  justify-content: center;
  padding: 0 100px;
  position: relative;
  gap: 30px;
  display: none;
}
.offerBanner.active {
  display: flex;
}
.offerBanner .bannerTitle {
  font-size: 16px;
  font-weight: 500;
}
.offerBanner .timerBox {
  display: flex;
  flex-direction: column;
}
.offerBanner .timerBox .timer {
  font-weight: 500;
}
.offerBanner .timerBox .timerTitles {
  display: flex;
  justify-content: space-between;
  padding: 0 3px;
}
.offerBanner .timerBox .timerTitles h4 {
  font-size: 12px;
  font-weight: 300;
}
.offerBanner ion-icon {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 25px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .offerBanner {
    display: flex;
    flex-direction: column;
    background-color: #2b2c33;
    color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px 50px;
    position: relative;
    gap: 10px;
    display: none;
  }
  .offerBanner.active {
    display: flex;
  }
  .offerBanner .bannerTitle {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .offerBanner .timerBox {
    display: flex;
    flex-direction: column;
  }
  .offerBanner .timerBox .timer {
    font-weight: 500;
  }
  .offerBanner .timerBox .timerTitles {
    display: flex;
    justify-content: space-between;
    padding: 0 3px;
  }
  .offerBanner .timerBox .timerTitles h4 {
    font-size: 12px;
    font-weight: 300;
  }
  .offerBanner ion-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    transform: none;
    font-size: 30px;
    cursor: pointer;
  }
}
.errorComponent {
  background-color: #222222;
  height: calc(100vh - 130px);
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: white;
}
.errorComponent img {
  height: 300px;
  margin-top: 20px;
}
.errorComponent h1 {
  margin: 0;
}
.errorComponent .desc {
  width: 40%;
  text-align: center;
  margin-top: 10px;
}
.errorComponent .learnmore {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5a01;
  color: #ffffff;
  border: none;
  padding: 12px 30px;
  font-size: 14px;
  cursor: pointer;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.errorComponent .learnmore ion-icon {
  font-size: 20px;
  margin-left: 10px;
}

.checkoutSuccess {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #222222;
}
.checkoutSuccess .navbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: transparent !important;
}
.checkoutSuccess .checkoutSuccessleft,
.checkoutSuccess .checkoutSuccessright {
  height: 100%;
  width: 50%;
}
.checkoutSuccess .checkoutSuccessleft {
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  padding-top: 180px;
  color: white;
  background-color: black;
}
.checkoutSuccess .checkoutSuccessleft .logoBox {
  margin-bottom: 70px;
}
.checkoutSuccess .checkoutSuccessleft .logoBox img {
  height: 80px;
}
.checkoutSuccess .checkoutSuccessleft .title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}
.checkoutSuccess .checkoutSuccessleft .subtitle {
  font-size: 18px;
  width: 70%;
  font-weight: 300;
  margin-left: 10px;
}
.checkoutSuccess .checkoutSuccessleft ul {
  font-size: 18px;
  margin-left: 40px;
  margin-bottom: 20px;
}
.checkoutSuccess .checkoutSuccessleft .detail {
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 450px;
  margin-top: 20px;
}
.checkoutSuccess .checkoutSuccessleft .detail .detailLeft .icon {
  display: flex;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: rgba(255, 90, 1, 0.2);
  justify-content: center;
  align-items: center;
  font-size: 25px;
  padding: 10px;
}
.checkoutSuccess .checkoutSuccessleft .detail .detailLeft .icon ion-icon {
  color: rgb(255, 90, 1);
}
.checkoutSuccess .checkoutSuccessleft .detail .detailRight {
  display: flex;
  flex-direction: column;
}
.checkoutSuccess .checkoutSuccessleft .detail .detailRight .key {
  font-weight: 500;
  font-size: 16px;
}
.checkoutSuccess .checkoutSuccessleft .detail .detailRight .urlBox {
  display: flex;
  width: 300px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}
.checkoutSuccess .checkoutSuccessleft .detail .detailRight .urlBox input {
  border: none;
  background-color: #2b2c33;
  color: white;
  width: 260px;
  padding: 5px 10px;
}
.checkoutSuccess .checkoutSuccessleft .detail .detailRight .urlBox button {
  width: 40px;
  border: none;
  color: #2b2c33;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkoutSuccess .checkoutSuccessleft .successMsg {
  color: #3ccf4e;
  margin-top: 5px;
  font-size: 14px;
  margin-left: 80px;
}
.checkoutSuccess .checkoutSuccessright {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkoutSuccess .checkoutSuccessright img {
  margin-top: 100px;
  height: 450px;
}

@media screen and (max-width: 768px) {
  .checkoutSuccess {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    background-color: #222222;
    padding-top: 100px;
  }
  .checkoutSuccess .navbar {
    display: none;
  }
  .checkoutSuccess .mobileNavbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
  }
  .checkoutSuccess .checkoutSuccessleft,
.checkoutSuccess .checkoutSuccessright {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
  }
  .checkoutSuccess .checkoutSuccessleft {
    display: flex;
    flex-direction: column;
    padding: 30px 10px;
    color: white;
    background-color: black;
  }
  .checkoutSuccess .checkoutSuccessleft .title {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
  }
  .checkoutSuccess .checkoutSuccessleft .subtitle {
    font-size: 18px;
    width: 100%;
    font-weight: 300;
    margin-left: 0;
  }
  .checkoutSuccess .checkoutSuccessleft ul {
    font-size: 18px;
    margin-left: 20px;
    margin-bottom: 0px;
  }
  .checkoutSuccess .checkoutSuccessleft .detail {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 90vw;
    overflow: hidden;
    margin-top: 20px;
    align-self: center;
  }
  .checkoutSuccess .checkoutSuccessleft .detail .detailLeft .icon {
    display: flex;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 90, 1, 0.2);
    justify-content: center;
    align-items: center;
    font-size: 25px;
    padding: 10px;
  }
  .checkoutSuccess .checkoutSuccessleft .detail .detailLeft .icon ion-icon {
    color: rgb(255, 90, 1);
  }
  .checkoutSuccess .checkoutSuccessleft .detail .detailRight {
    display: flex;
    flex-direction: column;
  }
  .checkoutSuccess .checkoutSuccessleft .detail .detailRight .key {
    font-weight: 500;
    font-size: 16px;
  }
  .checkoutSuccess .checkoutSuccessleft .detail .detailRight .urlBox {
    display: flex;
    width: 250px;
    height: 30px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
  }
  .checkoutSuccess .checkoutSuccessleft .detail .detailRight .urlBox input {
    border: none;
    background-color: #2b2c33;
    color: white;
    width: 220px;
    padding: 5px 10px;
  }
  .checkoutSuccess .checkoutSuccessleft .detail .detailRight .urlBox button {
    width: 30px;
    border: none;
    color: #2b2c33;
    cursor: pointer;
  }
  .checkoutSuccess .checkoutSuccessleft .successMsg {
    color: #3ccf4e;
    margin-top: 5px;
    font-size: 14px;
    margin-left: 80px;
  }
  .checkoutSuccess .checkoutSuccessright {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .checkoutSuccess .checkoutSuccessright img {
    margin-top: 100px;
    height: 200px;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: unset;
}

button,
.learnmore {
  outline: none;
  font-family: "Maven Pro" !important;
  font-weight: 500 !important;
}

body {
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  font-family: "Maven Pro", sans-serif !important;
}

input {
  outline: none;
}

p {
  line-height: normal !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #ff5a01 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 0;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #ff5a01;
  border: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.resMsg {
  margin-top: 5px;
  font-size: 16px;
  color: #3ccf4e;
}
.resMsg.error {
  color: #e64848;
}

button:disabled {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}/*# sourceMappingURL=index.css.map */
