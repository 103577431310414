@import "./variables";

.checkoutSuccess {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: $mainBg;

  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    // background-color: transparent;
    background-color: transparent !important;
  }

  .checkoutSuccessleft,
  .checkoutSuccessright {
    // border: 2px solid red;
    height: 100%;
    width: 50%;
  }

  .checkoutSuccessleft {
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
    padding-top: 180px;
    color: white;
    background-color: black;

    .logoBox {
      margin-bottom: 70px;

      img {
        height: 80px;
      }
    }

    .title {
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .subtitle {
      font-size: 18px;
      width: 70%;
      font-weight: 300;
      margin-left: 10px;
    }
    ul {
      font-size: 18px;
      margin-left: 40px;
      margin-bottom: 20px;
    }

    .detail {
      display: flex;
      gap: 20px;
      align-items: center;
      max-width: 450px;
      margin-top: 20px;

      .detailLeft {
        .icon {
          display: flex;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          background-color: rgba(255, 90, 1, 0.2);
          justify-content: center;
          align-items: center;
          font-size: 25px;
          padding: 10px;

          ion-icon {
            color: rgba(255, 90, 1, 1);
          }
        }
      }
      .detailRight {
        // border: 2px solid red;
        display: flex;
        flex-direction: column;

        .key {
          font-weight: 500;
          font-size: 16px;
        }

        .urlBox {
          // border: 2px solid red;
          display: flex;
          width: 300px;
          height: 40px;
          border-radius: 5px;
          overflow: hidden;
          margin-top: 10px;

          input {
            border: none;
            background-color: $drop;
            color: white;
            width: 260px;
            padding: 5px 10px;
          }

          button {
            width: 40px;
            border: none;
            color: $drop;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: rgba(255, 90, 1, 0.2);
          }
        }
      }
    }

    .successMsg {
      color: #3ccf4e;
      margin-top: 5px;
      font-size: 14px;
      margin-left: 80px;
    }
  }
  .checkoutSuccessright {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-top: 100px;
      height: 450px;
    }
  }
}

@media screen and (max-width: 768px) {
  .checkoutSuccess {
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    background-color: $mainBg;
    // border: 2px solid red;
    padding-top: 100px;

    .navbar {
      display: none;
    }
    .mobileNavbar {
      position: absolute;
      top: 0;
      left: 0;
      // border: 2px solid red;
      width: 100vw;
    }

    .checkoutSuccessleft,
    .checkoutSuccessright {
      // border: 2px solid red;
      height: fit-content;
      width: 100%;
    }

    .checkoutSuccessleft {
      display: flex;
      flex-direction: column;
      padding: 30px 10px;
      color: white;
      background-color: black;

      .title {
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 10px;
      }
      .subtitle {
        font-size: 18px;
        width: 100%;
        font-weight: 300;
        margin-left: 0;
      }
      ul {
        font-size: 18px;
        margin-left: 20px;
        margin-bottom: 0px;
      }

      .detail {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 90vw;
        overflow: hidden;
        margin-top: 20px;
        align-self: center;

        .detailLeft {
          .icon {
            display: flex;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            background-color: rgba(255, 90, 1, 0.2);
            justify-content: center;
            align-items: center;
            font-size: 25px;
            padding: 10px;

            ion-icon {
              color: rgba(255, 90, 1, 1);
            }
          }
        }
        .detailRight {
          // border: 2px solid red;
          display: flex;
          flex-direction: column;

          .key {
            font-weight: 500;
            font-size: 16px;
          }

          .urlBox {
            // border: 2px solid red;
            display: flex;
            width: 250px;
            height: 30px;
            border-radius: 5px;
            overflow: hidden;
            margin-top: 10px;

            input {
              border: none;
              background-color: $drop;
              color: white;
              width: 220px;
              padding: 5px 10px;
            }

            button {
              width: 30px;
              border: none;
              color: $drop;
              cursor: pointer;

              // background-color: rgba(255, 90, 1, 0.2);
            }
          }
        }
      }

      .successMsg {
        color: #3ccf4e;
        margin-top: 5px;
        font-size: 14px;
        margin-left: 80px;
      }
    }
    .checkoutSuccessright {
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 2px solid red;
      padding: 20px;

      img {
        margin-top: 100px;
        height: 200px;
      }
    }
  }
}
