@import "./variables";

.navbar {
  // border: 2px solid red;
  width: 100%;
  height: 130px;
  background-color: $mainBg;
  display: flex;
  align-items: center;
  top: 0;
  padding: 0 100px;

  .leftNav {
    width: 50%;
    // border: 2px solid red;
    display: flex;
    align-items: center;
    img {
      height: 70px;
    }
  }
  .rightNav {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 40px;

    .btns {
      // border: 2px solid red;
      .navBtn {
        padding: 15px 45px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        cursor: pointer;
        text-decoration: none;
        &.joinNow {
          //   padding: 11px 43px;
          clip-path: polygon(15% 0%, 100% 0%, 85.5% 100%, 0% 100%);
          background: $white;
          color: $orange;
        }
        &.freeClass {
          padding: 15px 35px;
          clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
          margin-left: -23.5px;
          background: $orange;
          color: $white;
        }
      }

      &.servicePage {
        .joinNow {
          display: none;
        }

        .freeClass {
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
        }
      }
    }

    .menus {
      // border: 2px solid red;
      margin-top: 20px;

      ul {
        display: flex;
        list-style: none;
        gap: 30px;

        li {
          position: relative;
          a {
            text-decoration: none;
            color: $white;
            font-weight: 400;
            font-size: 18px;
          }
          &.active::after {
            content: "";
            width: 48px;
            height: 5px;
            bottom: -12px;
            left: 0;
            transform: skew(-40deg);
            position: absolute;
            background-color: $orange;
          }
        }
      }
    }
  }
}

.mobileNavbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .mobileNavbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $mainBg;
    // position: sticky;
    // top: 0;
    // left: 0;
    // z-index: 5;

    .topNavbar {
      padding: 20px 0;

      img {
        height: 70px;
      }
    }
    .bottomNavbar {
      padding-bottom: 20px;
      .navBtn {
        padding: 15px 45px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        text-decoration: none;
        cursor: pointer;
        &.joinNow {
          //   padding: 11px 43px;
          clip-path: polygon(0% 0%, 100% 0%, 85.5% 100%, 0% 100%);
          background: $white;
          color: $orange;
        }
        &.freeClass {
          padding: 15px 35px;
          clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
          margin-left: -23.5px;
          background: $orange;
          // margin-top: 10px;
          color: $white;
        }
      }
      &.servicePageMobile {
        .joinNow {
          display: none;
        }
        .freeClass {
          padding: 15px 55px;
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
          // margin-top: 10px;
          margin-left: 0;
        }
      }
    }

    .hamburger {
      // width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 3;
      // display: none;

      .line {
        width: 35px;
        height: 3px;
        background: white;
        margin: 4.5px 0;
        transform-origin: left;
        transition: all 1.5s ease;
      }

      &.active {
        .line2 {
          opacity: 0;
        }
        .line1 {
          transform: rotate(45deg);
        }
        .line3 {
          transform: rotate(-45deg);
        }
      }
    }

    .mobileMenu {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: $mainBg;
      width: 100%;
      height: 100vh;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 1s ease;
      // border: 2px solid red;

      &.activeMenu {
        transform: scaleY(1);
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // border: 2px solid red;
        // height: fit-content;
        background-color: $orange;
        height: fit-content;
        width: 100%;
        padding-top: 80px;
        padding-bottom: 30px;
        gap: 20px;

        li {
          text-align: center;
          a {
            text-decoration: none;
            font-size: 25px;
            color: white;
            font-weight: 500;
          }
        }
      }

      .menuBottom {
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
        color: white;

        .menuSlogan {
          text-align: center;
          font-size: 20px;
        }
        .shortLinks {
          margin-top: 20px;
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          ion-icon {
            font-size: 30px;
            color: $orange;
          }
        }
        .emailId {
          font-size: 20px;
          text-align: center;
          span {
            font-size: 22px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
