@import "./variables";

.subscribe {
  background-color: $mainBg;
  width: 100%;
  padding: 30px 100px;
  color: white;
  display: flex;
  //   border: 2px solid red;

  .left {
    width: 60%;
    p {
      font-size: 34px;
      font-weight: 500;
      width: 80%;
      // border: 2px solid red;
    }
  }
  .right {
    // border: 2px solid red;
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    .container {
      border: 1px solid white;
      display: flex;
      align-items: center;
      height: 62px;

      img {
        height: 60px;
        width: 60px;
      }
      input {
        background-color: $mainBg;
        outline: none;
        border: none;
        padding: 10px;
        width: 350px;
        color: white;
        font-size: 18px;
        &::placeholder {
          color: rgb(178, 178, 178);
          text-transform: uppercase;
        }
      }
      button {
        padding: 10px;
        height: 60px;
        width: 60px;
        cursor: pointer;
        background-color: $orange;
        color: white;
        border: none;
        font-weight: 500;

        &:disabled{
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .subscribe {
    background-color: $mainBg;
    width: 100%;
    padding: 30px 10px;
    color: white;
    display: flex;
    flex-direction: column;

    // border: 2px solid red;

    .left {
      width: 100%;
      // border: 2px solid red;
      p {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        // border: 2px solid red;
        width: 100%;
      }
    }
    .right {
      // border: 2px solid red;
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .container {
        border: 1px solid white;
        display: flex;
        align-items: center;
        height: 62px;

        img {
          height: 60px;
          width: 60px;
        }
        input {
          background-color: $mainBg;
          outline: none;
          border: none;
          padding: 10px;
          width: 60vw;
          color: white;
          font-size: 18px;
          &::placeholder {
            color: rgb(178, 178, 178);
            text-transform: uppercase;
          }
        }
        button {
          padding: 10px;
          height: 60px;
          width: 60px;
          cursor: pointer;
          background-color: $orange;
          color: white;
          border: none;
          font-weight: 500;
        }
      }
    }
  }
}
